export const filetype = {
  //文件类型 0 用户 1图书 2新闻 3Excel文件 4图片 5视频 6音频 7标准 8工具书 9年鉴 10会议 11杂志 12作者 13期刊 14学位 15报纸 16合同 17机构 18广告
  user: 0,
  book: 1,
  news: 2,
  excel: 3,
  picture: 4,
  video: 5,
  audio: 6,
  standard: 7,
  toolbook: 8,
  yearbook: 9,
  conpaper: 10,
  magazine: 11,
  author: 12,
  journal: 13,
  thesis: 14,
  newspaper: 15,
  contract: 16,
  org: 17,
  ad: 18,
};
