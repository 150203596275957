<template>
	<div>
		<div class="nav-ul">
			<!--tab导航-->
			<ul
				id="myTabs"
				class="nav nav-tabs"
				role="tablist"
			>
				<!-- <li role="presentation" class="active">
        <a
          href="#recs"
          id="recs-tab"
          role="tab"
          data-toggle="tab"
          aria-controls="recs"
          aria-expanded="true"
          >新闻资讯</a
        >
      </li> -->
				<li
					v-for="(item, index) in TypeList"
					:class="item.class"
					:key="index"
					@click="TypeClick(item)"
				>
					<a>{{ item.name }}</a>
				</li>
			</ul>
		</div>
		<div class="content-wrap">

			<div
				id="myTabContent"
				class="tab-content"
			>
				<div
					role="tabpanel"
					class="tab-pane fade active in"
					id="recs"
					aria-labelledby="recs-tab"
				>
					<!-- <div class="total">
          <p>
            共检索<span>{{ total }}</span
            >条数据
          </p>
        </div> -->

					<div
						class="con-item news"
						v-if="type === 'news'"
					>
						<div class="news-list">
							<div class="newscontainer">
								<!-- <ul>
                <li>
                  <div class="row">
                    <div class="col-xs-4">
                      <a href="#"
                        ><img src="~@/assets/images/news-img-1.jpg" alt=""
                      /></a>
                    </div>
                    <div class="col-xs-8">
                      <div class="keys-text">
                        <h4>
                          春天正是读书时：商务印书馆2021年2月“十大好书”发布
                        </h4>
                        <p>2021-07-02</p>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="row">
                    <div class="col-xs-4">
                      <a href="#"
                        ><img src="~@/assets/images/news-img-2.jpg" alt=""
                      /></a>
                    </div>
                    <div class="col-xs-8">
                      <div class="keys-text">
                        <h4>
                          《中国画，好好看》：在古画中寻找中国人的精神故乡
                        </h4>
                        <p>2021-07-02</p>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="row">
                    <div class="col-xs-4">
                      <a href="#"
                        ><img src="~@/assets/images/news-img-1.jpg" alt=""
                      /></a>
                    </div>
                    <div class="col-xs-8">
                      <div class="keys-text">
                        <h4>
                          春天正是读书时：商务印书馆2021年2月“十大好书”发布
                        </h4>
                        <p>2021-07-02</p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul> -->

								<van-list
									v-model="vanListLoading"
									:finished="finished"
									:finished-text="finishedText"
									@load="onLoad(1)"
								>
									<li
										v-for="item in list"
										:key="item.doi"
										@click="goDetail('MobileNewsDetail', item, 1)"
									>
										<div class="row">
											<div class="col-xs-4">
												<a href="JavaScript:;"><img
														:src="item.sys_fld_coverpath"
														alt=""
													/></a>
											</div>
											<div class="col-xs-8">
												<div class="keys-text">
													<h4
														:title="item.title"
														v-html="item.name"
													></h4>
													<p>{{ item.pubdate }}</p>
												</div>
											</div>
										</div>
									</li>
								</van-list>
							</div>
						</div>
					</div>
					<!--活动预告-->
					<div
						class="con-item"
						v-if="type === 'activity'"
					>
						<div class="activity-list">
							<div class="newscontainer">
								<div class="row">
									<div class="col-xs-12">
										<van-list
											v-model="vanListLoading"
											:finished="finished"
											:finished-text="finishedText"
											@load="onLoad(0)"
										>
											<li
												v-for="item in list"
												:key="item.doi"
												@click="goDetail('MobileNewsDetail', item, 0)"
											>
												<h4>
													<a
														href="JavaScript:;"
														:title="item.title"
														v-html="item.name"
													></a>
												</h4>
												<p>{{ item.pubdate }}</p>
											</li>
										</van-list>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import * as Api from "@/services/activity"
import { replacePath, silceTime, replaceNew, isMobile } from "@/utils/util"
export default {
	name: "mobileNews",
	//   components: { swiper, swiperSlide, PdfStreamRead },
	props: ["keywords"],
	data () {
		return {
			TypeList: [
				{
					name: "新闻资讯",
					type: "news",
					typeid: 1,
					class: "active",
				},
				{
					name: "活动预告",
					type: "activity",
					class: "",
					typeid: 0,
				},
			],
			type: "news", //new 1    activity 0
			list: [],
			vanListLoading: false, // 加载状态
			finished: false, // 是否加载结束
			finishedText: "已加载全部", // 加载完成后的提示文案
			pages: 0, // 页数
			pnums: 10, // 条数
			keyword: "",
			total: 0,
		}
	},
	watch: {
		// $route: {
		//   handler: function (val, oldVal) {
		//     console.log(val + "" + oldVal);
		//   },
		//   // 深度观察监听
		//   deep: true,
		// },
		keywords: {
			handler (val) {
				this.list = []
				this.vanListLoading = false
				this.finished = false
				this.pages = 0
				this.pnums = 10
				this.total = 0
				this.keyword = val
				this.onLoad(this.type === "news" ? 1 : 0)
			},
			deep: true,
			immediate: true,
		},
	},
	computed: {},
	created () {
		//this.onLoad(1);
		this.Mobile()
		this.bindActive()
	},
	methods: {
		Mobile () {
			if (!isMobile()) {
				this.$router.push({ name: "newsIndex" })
			}
		},
		bindActive(){
			this.$emit("bindActive", "mobileNews");
		},
		goDetail (name, item, type) {
			this.$router.push({
				name: name,
				params: {
					doi: item.doi,
					type: type,
				},
			})
		},
		TypeClick (item) {
			this.TypeList.forEach((element) => {
				element.class = ""
			})
			item.class = "active"
			this.type = item.type
			this.list = []
			this.vanListLoading = false
			this.finished = false
			this.pages = 0
			this.pnums = 10
			this.total = 0
		},
		onLoad (type) {
			this.pages += 1
			Api.getNewsList({
				page: this.pages,
				pageSize: this.pnums,
				typeid: type,
				keyword: this.keyword,
			}).then((res) => {
				if (res.success) {
					this.vanListLoading = false
					const {
						data: { list, pageCount, count },
					} = res
					this.total = count
					if (type == 0) {
						let concatlist = list.map((e) => {
							return {
								doi: e.urlid,
								title: e.title,
								name: replaceNew(e.title, this.keyword),
								pubdate: silceTime(e.pubdate),
							}
						})
						this.list = this.list.concat(concatlist)
						// 如果当前页数 = 总页数加载结束
						if (this.pages >= pageCount) {
							this.finished = true
						}
					} else {
						let concatlist = list.map((e) => {
							return {
								doi: e.urlid,
								title: e.title,
								digest: e.digest,
								name: replaceNew(e.title, this.keyword),
								pubdate: silceTime(e.pubdate),
								sys_fld_coverpath: replacePath(e.sys_fld_coverpath),
							}
						})
						this.list = this.list.concat(concatlist)
						if (this.pages >= pageCount) {
							this.finished = true
						}
					}
				} else {
					this.list = []
					this.vanListLoading = false
					this.finished = true
				}
			})
		},
	},
};
</script>
<style lang="less" scoped>
.nav-ul {
	position: fixed;
	width: 100%;
	top: 1.2rem;
	z-index: 1;
	.nav {
		background: #fff;
		// padding: 0.12rem 0;
		height: 0.84rem;
	}
	.nav-tabs > li {
		float: left;
		width: 50%;
		text-align: center;
		margin-top: 0.1rem;
		line-height: initial;
		a {
			display: inline-block;
			font-size: 0.28rem;
			color: #666666;
			width: 70%;
			padding: 5px 0;
			border-bottom: 2px solid;
			border-color: transparent;
		}
	}
	.nav-tabs > li.active > a,
	.nav-tabs > li.active > a:focus,
	.nav-tabs > li.active > a:hover {
		border-top: none;
		border-left: none;
		border-right: none;
		border-bottom: 2px solid #d4000e;
		color: #1d1d1d;
		font-weight: bold;
	}
}
.content-wrap {
	padding-bottom: 1rem;
	margin-top: 2.1rem;
	.total {
		background: #fff;
		margin-top: 5px;
		p {
			font-size: 0.3rem;
			text-align: left;
			padding-left: 0.3rem;
			margin-bottom: 0;
			span {
				color: #d4000e;
			}
		}
	}
	.con-item {
		//margin-top: 5px;
		// padding: 20px 0;
		background: #fff;
		// ul {
		li {
			padding: 0.4rem 0;
			border-bottom: 2px solid #e9e9e9;
		}
		li:last-child {
			border-bottom: none;
			// padding-bottom: 0px;
		}
		//}
	}
	.news-list {
		width: 100%;
		.newscontainer {
			li {
				.row {
					display: -webkit-box;
					.col-xs-8 {
						display: -webkit-box;
						padding-right: 0.25rem;
						padding-left: 0.25rem;
						width: 66%;
					}
					.col-xs-4 {
						padding-right: 0px;
						width: 2.4rem;
						padding-left: 0.3rem;
						img {
							width: 100%;
							/* height: 100%; */
							height: 1.5rem;
							border-radius: 5px;
						}
					}
					.keys-text {
						height: 100%;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-box-flex: 1;
						-webkit-box-pack: justify;
						h4 {
							font-size: 0.28rem;
							line-height: 0.48rem;
							font-weight: bold;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 2;
							-webkit-box-orient: vertical;
							margin-bottom: 0;
						}
						p {
							font-size: 0.3rem;
							color: #787878;
							margin-bottom: 0;
							line-height: 0.4rem;
						}
					}
				}
			}
		}
	}
	.activity-list {
		width: 100%;
		.newscontainer {
			padding-right: 0.3rem;
			padding-left: 0.3rem;
			margin-right: auto;
			margin-left: auto;
			// ul {
			li {
				h4 {
					margin-bottom: 0;
					height: 1.2rem;
					a {
						font-size: 0.28rem;
						line-height: 0.6rem;
						font-weight: bold;
						overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 2;
							-webkit-box-orient: vertical;
						
					}
				}
				p {
					margin-bottom: 0;
					color: #787878;
					padding-top: 0.2rem;
				}
			}
		}
		//}
	}
}
</style>
<style scoped>
</style>
