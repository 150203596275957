import { render, staticRenderFns } from "./Video.vue?vue&type=template&id=25efef5c&scoped=true&"
import script from "./Video.vue?vue&type=script&lang=js&"
export * from "./Video.vue?vue&type=script&lang=js&"
import style0 from "./Video.vue?vue&type=style&index=0&id=25efef5c&lang=less&scoped=true&"
import style1 from "./Video.vue?vue&type=style&index=1&lang=less&"
import style2 from "./Video.vue?vue&type=style&index=2&id=25efef5c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25efef5c",
  null
  
)

export default component.exports