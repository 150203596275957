<template>
  <div>
    <div id="main">
      <div class="play">
        <div class="container">
          <div class="main-item position">
            <a @click="goToView('/home/index')">首页</a>
            &gt;
            <a @click="goToView('/home/bookIndex')">民生读物</a>
            &gt;
            <span>视频详情</span>
          </div>
          <!-- <div class="main-item video-player">
            <img src="~@/assets/images/video-player.jpg" alt="" />
          </div> -->
          <video-player
            class="main-item video-player"
            ref="videoPlayer"
            :playsinline="true"
            :options="playerOptions"
          >
          </video-player>
          <div class="main-item video-info">
            <h4>{{ detail.name }}</h4>
            <p>
              <span>{{ detail.author }}</span>
              <span>{{ detail.issuedate }}</span>
              <span><i></i>{{ detail.source }}</span>
              <a @click="favoritesFun(detail)"
                >收藏
                <!-- <i :class="{'isFaver':favorites}"></i>  -->
                <a-icon
                  type="heart"
                  class="hertClass"
                  :class="{ isFaver: favorites }"
              /></a>
            </p>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="box">
          <div class="main-item brief">
            <div class="title">
              <span><i></i>视频简介</span>
            </div>
            <div class="con">
              <p>
                {{ detail.description }}
              </p>
            </div>
          </div>
          <div class="main-item rec">
            <div class="title">
              <span><i></i>推荐视频</span>
            </div>

            <a-list :data-source="dataResource">
              <a-list-item
                slot="renderItem"
                :key="item.doi"
                slot-scope="item"
                class="pic"
                :title="item.name"
                style="width: 232px"
                @click="goDetail('videoDetail', item.doi)"
              >
                <a-card hoverable style="width: 232px" class="imgbox">
                  <img slot="cover" alt="example" :src="item.imgurl" />
                  <a slot="cover" class="icon-play"><img src="~@/assets/images/icon-play.png"/></a>
                  <span slot="cover"></span>
                  <a-card-meta :title="item.name">
                    <template slot="description">
                      <p>{{ item.source }}</p>
                    </template>
                  </a-card-meta>
                </a-card>
              </a-list-item>
            </a-list>
            <div class="more">
              <a @click="goToView('/home/bookIndex')">查看更多</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";
import * as Api from "@/services/video";
import { replacePath, replaceVideoPath,isMobile } from "@/utils/util";
import { recommendDoiList } from "@/config/constConfig";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "Video",
  components: { videoPlayer },
  data() {
    return {
      favorites: false,
      doi: this.$route.params.doi,
      detail: {},
      // 视频播放
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], //可选择的播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [],
        poster: "", //你的封面地址
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, //当前时间和持续时间的分隔符
          durationDisplay: true, //显示持续时间
          remainingTimeDisplay: false, //是否显示剩余时间功能
          fullscreenToggle: true, //全屏按钮
        },
      },
      dataResource: [],
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  watch: {
    $route(to) {
      this.doi = to.params.doi;
      this.initDetail();
      this.getIsFav()
    },
  },
  created() {
    this.Mobile()
    this.initDetail();
    this.initRecommend(recommendDoiList.detailVideoDoi);
    //查询是否收藏
    this.getIsFav();
  },
  methods: {
    ...mapMutations("account", ["saveNowRouter"]),
    Mobile(){
      if(isMobile()){
        this.$router.push({ name: 'MobileVideoDetail', params: { doi: this.$route.params.doi } });
      }
    },
    //是否已经收藏
    getIsFav() {
      if (!this.user || this.user === "") {
        return;
      }
      let obj = {
        doi: this.$route.params.doi,
        restype: "5",
        username: this.user,
      };
      Api.getIsFav(obj).then((res) => {
        if (res.success) {
          this.favorites = true;
        } else {
          this.favorites = false;
        }
      });
    },
    //收藏/取消收藏
    favoritesFun(item) {
      var vm = this
      if (!this.user || this.user === '') {
        this.$confirm({
          title: "确认提示",
          content: `收藏需要先登录，是否确认登录？`,
          okType: "warning",
          onOk() {
            vm.saveNowRouter(vm.$route)
            vm.$router.push({ name: "Login"});
          },
          onCancel() {
            console.log('取消')
          }
        });
        // this.$notification["warning"]({
        //   message: "警告",
        //   description: "请先登录!",
        //   placement: "topLeft",
        //   style: {
        //     width: "500px",
        //   },
        // });
        return;
      }
      if (localStorage.getItem('expireAt') && (new Date().getTime() >= new Date(localStorage.getItem('expireAt')).getTime())) {
        this.$confirm({
          title: "确认提示",
          content: `登录已过期，该操作需重新登录，是否重新登录？`,
          okType: "warning",
          onOk() {
            vm.saveNowRouter(vm.$route)
            vm.$router.push({ name: "Login"});
          },
          onCancel() {
            console.log('取消')
          }
        });
        // this.$notification["warning"]({
        //   message: "警告",
        //   description: "toiken已过期，请重新登录再进行操作!",
        //   placement: "topLeft",
        //   style: {
        //     width: "500px",
        //   },
        // });
        return
      }
      let obj = {
        booktype: "5",
        doi: this.$route.params.doi,
        name: item.name,
        operator: this.user,
      };
      Api.addFavoriteData(obj).then((res) => {
        if (res.success) {
           this.$message.success(res.message);
          // this.$notification["success"]({
          //   message: "成功",
          //   description: res.message,
          //   placement: "topLeft",
          //   style: {
          //     width: "500px",
          //   },
          // });
          this.favorites = !this.favorites;
        } else {
          this.$message.error(res.message);
          // this.$notification["warning"]({
          //   message: "警告",
          //   description: res.message,
          //   placement: "topLeft",
          //   style: {
          //     width: "500px",
          //   },
          // });
        }
      });
    },
     //浏览记录
    addLog(item) {
      if (!this.user || this.user === '') {        
        return
      }
      if (localStorage.getItem('expireAt') && (new Date().getTime() >= new Date(localStorage.getItem('expireAt')).getTime())) {
        return
      }
      let obj = {
        resourcetype: "5",
        resourcedoi: this.$route.params.doi,
        resourcename: item.name,
        username: this.user,
      };
      Api.addLog(obj)
    },
    goDetail(name, doi) {
      this.$router.push({ name: name, params: { doi: doi } });
    },
    goToView(path) {
      this.$router.push({ path: path });
    },
    initRecommend(typeDoi) {
      Api.getRecommendByType({ page: 1, pageSize: 4, type: typeDoi }).then(
        (res) => {
          const { success } = res;
          if (success) {
            const {
              data: { list },
            } = res;
            this.dataResource = list.map((e) => {
              return {
                doi: e.sys_fld_doi,
                name: e.name,
                imgurl: replacePath(e.sys_fld_coverpath, "0", "1"),
                source: e.source,
              };
            });
          } else {
            this.dataResource = [];
          }
        }
      );
    },
    initDetail() {
      let _this = this;
      Api.getInfo(this.doi).then((res) => {
        const { success } = res;
        //console.log(res)
        if (success) {
          const {
            data: { data },
          } = res;
          _this.detail = data;
          _this.detail.sys_fld_coverpath = replacePath(
            data.sys_fld_coverpath,
            "1"
          );
          _this.detail.sys_fld_filepath = replaceVideoPath(
            data.sys_fld_filepath
          );
          _this.playerOptions.sources=[]
          _this.playerOptions.sources.push({
            src: _this.detail.sys_fld_filepath, // 路径
            type: "video/mp4", // 类型
          });
          //console.log(_this.playerOptions)
          _this.playerOptions.poster = _this.detail.sys_fld_coverpath; //你的封面地址
          //console.log(_this.playerOptions)
          this.addLog(_this.detail)
        } else {
          _this.detail = {};
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "~@/assets/css/details-video.less";
</style>
<style lang="less">
#main .play .video-info p a i {
  background: none !important;
}
.video-player {
  .video-js {
    .vjs-big-play-button {
      left: 45%;
      top: 45%;
      width: 2em;
      height: 2em;
      line-height: 2em;
      border-radius: 3em;
      font-size: 5em;
    }
  }
}
.video-player {
  video {
    width: 100%;
    height: 100%;
  }
}
.video-info {
  .isFaver {
    color: #d4000e;
  }
}
.rec {
  .ant-list-items {
    padding: 20px;
    .ant-card-cover {
      height: 130px;
      img {
        height: 100%;
      }
    }
  }
  .ant-card-body {
    padding: 0 8px;
    .ant-card-meta-title {
      width: 100%;
      font-size: 16px;
      padding: 15px 0;
      border-bottom: 2px solid #e9e9e9;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      vertical-align: middle;
      margin-bottom: 0;
    }
    .ant-card-meta-description {
      p {
        font-size: 14px;
        color: #8d8d8d;
        line-height: 32px;
        margin-bottom: 0;
        height: 32px;
      }
    }
  }
}
</style>
<style lang="less" scoped>
.imgbox {
  position: relative;
  .icon-play {
    width: 80px;
    height: 80px !important;
    position: absolute;
    left: 50%;
    top: 26px;
    margin-left: -40px;
  }
}
</style>
