<template>
  <div id="main">
    <div class="container">
      <div class="main-item position">
        <a @click="goToView('/home/index')">首页</a>
        &gt;
        <span>图书详情</span>
      </div>
      <div class="main-item">
        <div class="title">
          <span><i></i>图书详情</span>
        </div>
        <div class="detail clearfix">
          <div class="show-pic fl">
            <img :src="bookDetails.pictruepath" />
          </div>
          <div class="show-info fl">
            <h3>{{ bookDetails.name }}</h3>
            <p>
              {{ bookDetails.digest }}
            </p>
            <span>{{ bookDetails.author }} 著</span>
            <ul class="clearfix">
              <li><span>出版社：</span>{{ bookDetails.issuedep }}</li>
              <li><span>ISBN：</span>{{ bookDetails.isbn }}</li>
              <li><span>出版时间：</span>{{ bookDetails.issuedate }}</li>
              <li><span>字数：</span>{{ bookDetails.charcount }}</li>
            </ul>
            <div class="btn">
              <!-- <a class="btn-read" @click="openPdf"> 在线阅读 </a> -->
              <a class="btn-read" @click="openPdf"> 在线阅读 </a>
              <a class="btn-collect" @click="favoritesFun(bookDetails)"
                ><a-icon
                  type="heart"
                  :theme="favorites?'filled':'outlined'"
                  class="hertClass"
                  :class="{ isFavorites: favorites }"
                />收藏</a
              >
            </div>
          </div>
          <div class="show-wx">
            <img :src="this.qrPic" />
            <p>扫描二维码，登录手机端</p>
          </div>
        </div>
      </div>
      <div class="main-item">
        <div class="title">
          <span><i></i>推荐图书</span>
        </div>
        <div class="recommendPage recommend">
          <swiper
            :options="swiperOption"
            ref="mySwiper"
            class="swiper-no-swiping"
          >
            <swiper-slide v-for="(item, index) in recommendList" :key="index">
              <div class="imgbox">
                <img
                  :src="item.pictruepath"
                  :title="item.name"
                  @click="goDetail('bookDetail', item.sys_fld_doi)"
                />
                <div
                  class="introduction"
                  :title="item.name"
                  @click="goDetail('bookDetail', item.sys_fld_doi)"
                >
                  {{ item.name }}
                </div>
              </div>
            </swiper-slide>
            <!-- <div class="swiper-pagination" slot="pagination"></div> -->
            <div
              class="swiper-button-prev"
              slot="button-prev"
              @click="goPre"
            ></div>
            <div
              class="swiper-button-next"
              slot="button-next"
              @click="goNext"
            ></div>
          </swiper>
        </div>
      </div>
      <div class="main-item clearfix bottomClass">
        <div class="rank fl">
          <h4>排行榜</h4>
          <ul>
            <li
              v-for="(item, index) in leaderboardList"
              :key="index"
              @click="goDetail('bookDetail', item.sys_fld_doi)"
            >
              <span>{{ index + 1 }}</span>
              <a :title="item.name">{{ item.name }}</a>
            </li>
          </ul>
        </div>
        <div class="book-detail fr">
          <div class="tabBtnGroup" v-if="titleList!==null&&titleList.length>0">
            <div
              class="tabBtn"
              v-for="(item, index) in titleList"
              :key="index"
              :class="{ isActive: item.isactive }"
              @click="saveActive(item)"
            >
              <a-anchor :affix="false" @click="handleAnchorClick">
                <a-anchor-link
                  :href="item.id"
                  :title="item.name"
                ></a-anchor-link>
              </a-anchor>
            </div>
          </div>
          <div
            class="detail-item brief"
            id="Introduction"
            v-if="bookDetails.digest !== null && bookDetails.digest.length > 0"
          >
            <h3><span>简介</span></h3>
            <p>
              {{ bookDetails.digest }}
            </p>
          </div>
          <div
            class="detail-item author"
            id="Author"
            v-if="
              bookDetails.authordesc !== null &&
              bookDetails.authordesc.length > 0
            "
          >
            <h3><span>作者</span></h3>
            <p>
              {{ bookDetails.authordesc }}
            </p>
          </div>
          <div
            id="tableContents"
            class="detail-item catalog"
            v-if="tableOfContents.length > 0"
          >
            <h3><span>目录</span></h3>
            <ul>
              <li>
                <a>{{ bookDetails.name }}</a>
                <ul>
                  <li v-for="(item, index) in tableOfContents" :key="index">
                    <a>{{ item.name }}</a>
                  </li>
                </ul>
              </li>
            </ul>
            <div
              class="more"
              @click="goMore()"
              v-if="oldTableOfContents.length > 5 && tableOfContents.length < 6"
            >
              <a>查看更多</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <a-modal
      width="40%"
      title="PDF阅读"
      :visible="pdfread"
      class="modal-read"
      :keyboard="false"
      :closable="true"
      :footer="null"
      :dialog-style="{ top: '3%' }"
      @cancel="handleCancelpdf"
      :destroyOnClose="true"
      :maskClosable="true"
    >
      <PdfStreamRead :pdfReabUrl="pdfUrl" />
    </a-modal> -->
  </div>
</template>

<script>
//import PdfStreamRead from "@/components/common/PdfBigStreamRead";
import * as homeApi from "@/services/home";
import { filetype } from "@/config/fileType";
import { replacePath, replaceAll, replaceStr, isMobile } from "@/utils/util";
//import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import { mapGetters, mapMutations } from "vuex";
var vm = this;
export default {
  name: "Book",
  //components: { swiper, swiperSlide },
  data() {
    return {
      titleList: [
        //{ id: "Introduction", name: "简介", isactive: true },
        //{ id: "Author", name: "作者", isactive: false },
        // {id:'tableContents',name:'目录',isactive:false}
      ],
      leaderboardList: [], //排行榜
      pdfUrl: "",
      pdfread: false,
      nowindex: 0,
      timeout: null, //防抖函数
      favorites: false,
      tableOfContents: [],
      oldTableOfContents: [],
      recommendDetails: {},
      recommendList: [], //推荐图书
      bookDetails: {}, //图书详情
      swiperOption: {
        // loop: true,
        direction: "horizontal",
        initialSlide: 3,
        slidesPerView: "auto",
        centeredSlides: false,
        spaceBetween: 43,
        // 显示分页
        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true, //允许分页点击跳转
        // },
        on: {
          click: function () {},
          slideChangeTransitionStart: function () {
            // this.swiper.slideTo(4, 400, false);
            vm.getClickIndex(this.realIndex);
          },
          slideChangeTransitionEnd: function () {
            // this.swiper.slideTo(4, 400, false);
            // vm.getClickIndex(this.realIndex);
          },
          tap: function () {},
          transitionStart() {
            // 开始translate之前触发
          },
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      //mobileUrl:'http://10.121.4.179:8080/jdrp#/item/bookDetail/c1afe49d-29cc-4d36-aa11-9b4018b8987e',
      qrPic: "",
    };
  },
  computed: {
    // swiper() {
    //   return this.$refs.mySwiper.swiper;
    // },
    ...mapGetters("account", ["user"]),
  },
  watch: {
    $route(to) {
      this.doi = to.params.doi;
      this.getBookDetails();
      this.getRecommendList();
      this.getBookList();
    },
  },
  mounted() {
    //console.log("Current Swiper instance object", this.swiper);
    // this.swiper.slideTo(4, 400, false)
  },
  created() {
    vm = this;
    this.Mobile();
    //获取图书详情
    this.getBookDetails();
    //获取推荐图书
    this.getRecommendList();
    //排行榜
    this.getBookList();
  },
  methods: {
    Mobile() {
      if (isMobile()) {
        this.$router.push({
          name: "MobileBookDetail",
          params: { doi: this.$route.params.doi },
        });
      }
    },
    saveActive(item) {
      this.titleList.map(function (data) {
        data.isactive = false;
      });
      item.isactive = true;
    },
    handleAnchorClick(e, link) {
      // 阻止点击的默认事件修改路由
      e.preventDefault();
      var srcolls = document.getElementById(link.href);
      srcolls.scrollIntoView({ block: "start", behavior: "smooth" });
    },
    ...mapMutations("account", ["saveNowRouter"]),
    openPdf() {
       var vm = this;
      if (!this.user || this.user === "") {
        this.$confirm({
          title: "确认提示",
          content: `登录后即可阅读，是否确认登录？`,
          okType: "warning",
          onOk() {
            vm.saveNowRouter(vm.$route);
            vm.$router.push({ name: "Login" });
          },
          onCancel() {
            console.log("取消");
          },
        });
        return;
      }
      if (
        localStorage.getItem("expireAt") &&
        new Date().getTime() >=
          new Date(localStorage.getItem("expireAt")).getTime()
      ) {
        this.$confirm({
          title: "确认提示",
          content: `登录已过期，该操作需重新登录，是否重新登录？`,
          okType: "warning",
          onOk() {
            vm.saveNowRouter(vm.$route);
            vm.$router.push({ name: "Login" });
          },
          onCancel() {
            console.log("取消");
          },
        });
        return;
      }
      //this.pdfread = true
      // window.open(baseURL + '/registercode/exportExcel/' +_self.batchnumber, "_blank");path: '/article', query: {  id: 123 } }
      let link = this.$router.resolve({
        path: "/item/pdfRead",
        query: { pdfUrl: this.pdfUrl },
      });
      window.open(link.href, "_blank");
    },
    handleCancelpdf() {
      this.pdfread = false;
    },
    goPre() {
      this.swiper.slideTo(this.nowindex - 6, 400, false);
      if (this.nowindex - 6 <= 0) {
        this.nowindex = 0;
      }
    },
    goNext() {
      this.swiper.slideTo(this.nowindex + 6, 400, false);
      if (this.nowindex + 6 > this.recommendList.length - 1) {
        this.nowindex = this.recommendList.length - 1;
      }
    },
    goToView(path) {
      this.$router.push({ path: path });
    },
    goDetail(name, doi) {
      this.$router.push({ name: name, params: { doi: doi } });
    },
    //获取是否已经收藏
    getIsFav() {
      if (!this.user || this.user === "") {
        return;
      }
      let obj = {
        doi: this.$route.params.doi,
        restype: "1",
        username: this.user,
      };
      homeApi.getIsFav(obj).then((res) => {
        if (res.success) {
          this.favorites = true;
        } else {
          this.favorites = false;
        }
      });
    },
    //收藏/取消收藏
    favoritesFun(item) {
      var vm = this;
      if (!this.user || this.user === "") {
        this.$confirm({
          title: "确认提示",
          content: `收藏需要先登录，是否确认登录？`,
          okType: "warning",
          onOk() {
            vm.saveNowRouter(vm.$route);
            vm.$router.push({ name: "Login" });
          },
          onCancel() {
            console.log("取消");
          },
        });
        // this.$notification["warning"]({
        //   message: "警告",
        //   description: "请先登录!",
        //   placement: "topLeft",
        //   style: {
        //     width: "500px",
        //   },
        // });
        // this.$message.warning("请先登录");
        return;
      }
      if (
        localStorage.getItem("expireAt") &&
        new Date().getTime() >=
          new Date(localStorage.getItem("expireAt")).getTime()
      ) {
        this.$confirm({
          title: "确认提示",
          content: `登录已过期，该操作需重新登录，是否重新登录？`,
          okType: "warning",
          onOk() {
            vm.saveNowRouter(vm.$route);
            vm.$router.push({ name: "Login" });
          },
          onCancel() {
            console.log("取消");
          },
        });
        // this.$notification["warning"]({
        //   message: "警告",
        //   description: "toiken已过期，请重新登录再进行操作!",
        //   placement: "topLeft",
        //   style: {
        //     width: "500px",
        //   },
        // });
        return;
      }
      let obj = {
        booktype: "1",
        doi: this.$route.params.doi,
        name: item.name,
        operator: this.user,
      };
      homeApi.addFavoriteData(obj).then((res) => {
        if (res.success) {
          this.$message.success(res.message);
          // this.$notification["success"]({
          //   message: "成功",
          //   description: res.message,
          //   placement: "topLeft",
          //   style: {
          //     width: "500px",
          //   },
          // });
          this.favorites = !this.favorites;
        } else {
          this.$message.error(res.message);
          // this.$notification["warning"]({
          //   message: "警告",
          //   description: res.message,
          //   placement: "topLeft",
          //   style: {
          //     width: "500px",
          //   },
          // });
          // this.$message.error(res.message);
        }
      });
    },
    //浏览记录
    addLog(item) {
      if (!this.user || this.user === "") {
        return;
      }
      if (
        localStorage.getItem("expireAt") &&
        new Date().getTime() >=
          new Date(localStorage.getItem("expireAt")).getTime()
      ) {
        return;
      }
      let obj = {
        resourcetype: "1",
        resourcedoi: this.$route.params.doi,
        resourcename: item.name,
        username: this.user,
      };
      homeApi.addLog(obj);
    },
    //查看更多
    goMore() {
      this.tableOfContents = this.oldTableOfContents;
    },
    //获取当前目录
    getCatalogByid(id, flagdigest, flagauthor) {
      var vm = this;
      //获取当前目录
      var obj = {
        id: id,
      };
      this.tableOfContents = [];
      this.oldTableOfContents = [];
      vm.titleList=[]
      if (flagdigest) {
        vm.titleList.push({ name: "简介", isactive: true, type: "1" });
      }
      if (flagauthor) {
        vm.titleList.push({
          name: "作者",
          isactive: vm.titleList !== null && vm.titleList.length > 0 ? false : true,
          type: "2",
        });
      }

      homeApi.getCatalogByid(obj).then((res) => {
        if (res.success) {
          vm.oldTableOfContents = res.data.list;
          vm.oldTableOfContents.map(function (item, index) {
            if (index < 5) {
              vm.tableOfContents.push(item);
            }
          });
          if (vm.tableOfContents.length > 0) {
            // vm.titleList.push({
            //   id: "tableContents",
            //   name: "目录",
            //   isactive: false,
            // });
             vm.titleList.push({ name: "目录", isactive: vm.titleList!==null&&vm.titleList.length>0?false:true, type: "3" });
          }
        } else {
          vm.tableOfContents = [];
          vm.oldTableOfContents = [];
        }
      });
    },
    getClickIndex(index) {
      if (JSON.stringify(this.bookDetails) === "{}") {
        return;
      }
      this.nowindex = index;
      // var vm = this;
      // this.recommendDetails = this.recommendList[index];
      // 清除定时器  防抖操作 只执行最后一次点击
      // if (this.timeout !== null) clearTimeout(this.timeout);
      // this.timeout = setTimeout(function () {
      //   vm.getCatalogByid("E322984F-EC21-418f-8FCB-EF208FF2365D");
      // }, 500);
      // vm.getCatalogByid(vm.recommendDetails.bookid);
    },
    getRecommendList() {
      let obj = {
        // typeDoi: "666c6b27-8626-4027-a300-914a6eb16c43",
        typeDoi: "666c6b27-8626-4027-a300-914a6eb16c43",
        page: 1,
        size: 1000,
      };
      homeApi.getRecommendList(obj).then((res) => {
        if (res.success) {
          this.recommendList = res.data.list;
          this.recommendList.map((item) => {
            item.pictruepath = replacePath(item.sys_fld_coverpath);
          });
          // this.swiper.slideTo(3, 0, false);
          // if (this.recommendList.length >= 4) {
          //   this.recommendDetails = this.recommendList[3];
          // } else if (this.recommendList.length > 0) {
          //   this.recommendDetails =
          //     this.recommendList[this.recommendList.length - 1];
          // }
          // this.getCatalogByid(this.recommendDetails.bookid);
        } else {
          this.recommendList = [];
        }
      });
    },
    //排行榜
    getBookList() {
      let obj = {
        page: 1,
        size: 10,
      };
      homeApi.getBookList(obj).then((res) => {
        if (res.success) {
          this.leaderboardList = res.data.list;
        } else {
          this.leaderboardList = [];
        }
      });
    },
    getBookDetails() {
      var url = window.location.href;
      var phoneUrl = replaceStr(
        url.toLowerCase(),
        "item/bookdetail",
        "mobileItem/mobilebookdetail"
      );
      let getQRCode = {
        url: phoneUrl,
        type: filetype.book,
        doi: this.$route.params.doi,
      };
      let obj = {
        doi: this.$route.params.doi,
      };
      homeApi.getBookDetails(obj).then((res) => {
        if (res.success) {
          this.bookDetails = res.data.data;
          this.bookDetails.pictruepath = replacePath(
            this.bookDetails.sys_fld_coverpath
          );
          let flagdigest =
            this.bookDetails.digest !== null &&
            this.bookDetails.digest.length > 0;
          let flagauthor =
            this.bookDetails.authordesc !== null &&
            this.bookDetails.authordesc.length > 0;
          this.pdfUrl = replaceAll(this.bookDetails.sys_fld_filepath);
          //this.pdfUrl="/book/1e9ad9e1-fe82-4798-a6a5-d3d5e6102093/1e9ad9e1-fe82-4798-a6a5-d3d5e6102093.pdf"
          //this.addLog(this.bookDetails);
          this.getIsFav(this.bookDetails);
          this.getCatalogByid(
            this.bookDetails.sys_fld_doi,
            flagdigest,
            flagauthor
          );
          //增加浏览记录
          this.addLog(this.bookDetails);
          this.getQRCode(getQRCode);
        } else {
          this.bookDetails = {};
        }
      });
    },
    callback(key) {
      console.log(key);
    },
    //二维码
    getQRCode(getQRCode) {
      homeApi.getQRCode(getQRCode).then((res) => {
        if (res.success) {
          this.qrPic = replacePath(res.data.qrpath);
        } else {
          this.qrPic = replacePath("");
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "~@/assets/css/home.less";
</style>
<style lang="less" scoped>
.recommendPage {
  .swiper-container {
    position: relative;
    width: 100%;
    height: 218px;
    padding: 0px 60px;
  }
}
.recommendPage {
  .swiper-container {
    .swiper-slide {
      width: 110px;
      line-height: 188px;
      color: #000;
      font-size: 16px;
      text-align: center;
    }
  }
}
#main {
  background: #fff;
}
.bottomClass {
  padding-bottom: 40px;
}
.main-item {
  .catalog > ul > li > a {
    font-size: 16px;
    width: 100%;
    padding: 0 20px;
    font-weight: bold;
    border-bottom: 1px dashed #d9d9d9;
  }
  .catalog > ul > li > ul > li a {
    font-size: 14px;
    color: #3b3b3b;
    padding: 0 20px;
    line-height: 46px;
  }
  .catalog {
    .more {
      padding-top: 10px;
      padding-left: 20px;
      a {
        font-size: 16px;
        color: #0066cc;
      }
    }
  }
  .detail-item {
    padding-top: 40px;
    h3 {
      height: 32px;
      line-height: 32px;
      border-bottom: 1px solid #df3f3b;
      margin-bottom: 20px;
      span {
        font-size: 18px;
        color: #fff;
        width: 128px;
        height: 32px;
        text-align: center;
        background: url(../../assets/images/title-bg.jpg);
      }
    }
    p {
      font-size: 16px;
      line-height: 28px;
      color: #3b3b3b;
      text-indent: 2em;
    }
  }
  .book-detail {
    width: 910px;
    margin-top: 20px;
  }
  .fr {
    float: right;
  }
  .rank {
    width: 256px;
    border: 1px solid #d7d7d7;
    margin-top: 20px;
    border-radius: 6px;
    h4 {
      width: 100%;
      text-align: center;
      height: 46px;
      line-height: 46px;
      background: #d4000e;
      font-size: 18px;
      color: #fff;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
    ul {
      padding: 18px;
      li {
        a {
          width: 86%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          vertical-align: middle;
        }
      }
    }
  }
  .recommend {
    padding: 0px 20px;
    position: relative;
    .imgbox {
      cursor: pointer;
      width: 100%;
      height: 188px;
      position: relative;
      img {
        width: 100%;
        height: 63%;
      }
      .introduction {
        position: absolute;
        left: 0;
        right: 9px;
        bottom: -75px;
        font-size: 14px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
    }
  }
  .title {
    height: 48px;
    line-height: 48px;
    background: #f6f6f6;
    border-bottom: 2px solid #d4000e;
    padding: 0 20px;
  }
  .detail {
    padding: 30px 20px;
    position: relative;
    .show-wx {
      position: absolute;
      right: 20px;
      bottom: 40px;
      width: 194px;
      height: 184px;
      border: 1px solid #e2e2e2;
      background: #f6f6f6;
      text-align: center;
      img {
        width: 116px;
        height: 116px;
        margin: 18px auto 0;
      }
      p {
        font-size: 14px;
        color: #666666;
        padding-top: 10px;
      }
    }
    .show-pic {
      width: 334px;
      height: 346px;
      text-align: center;
      border: 1px solid #d7d7d7;
      padding: 20px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .fl {
      float: left;
    }
    .show-info {
      width: 780px;
      margin-left: 30px;
      h3 {
        font-size: 28px;
      }
      p {
        font-size: 18px;
        color: #a3a3a3;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
      span {
        font-size: 18px;
        display: inline-block;
      }
      ul {
        width: 570px;
        padding-top: 20px;
        li {
          float: left;
          width: 50%;
          font-size: 18px;
          line-height: 36px;
        }
      }
      .btn {
        padding-top: 30px;
        width: 530px;
        .btn-read {
          font-size: 20px;
          color: #fff;
          padding: 12px 36px;
          border-radius: 4px;
          background: #d4000e;
        }
        .btn-collect {
          float: right;
          width: 66px;
          font-size: 16px;
          padding: 15px 0;
          .hertClass {
            margin-right: 5px;
          }
          .isFavorites {
            color: #d4000e;
          }
        }
      }
    }
  }
  .clearfix {
    overflow: hidden;
    zoom: 100%;
  }
  ul,
  ol,
  li {
    list-style: none;
  }
}
.tabBtnGroup {
  width: 100%;
  height: 46px;
  background: #ccc;
  .tabBtn {
    width: 116px;
    height: 46px;
    line-height: 46px;
    font-size: 18px;
    float: left;
  }
}
</style>
<style scoped>
.recommend >>> .swiper-button-prev,
.swiper-button-next {
  top: 58px;
  width: 50px;
  height: 140px;
  background-color: #fff;
}
.recommend >>> .swiper-button-prev {
  left: 0;
}
.recommend >>> .swiper-button-next {
  right: 0;
}
.tabBtn >>> .ant-anchor-ink {
  height: 0;
  display: none;
}
.tabBtn >>> .ant-anchor-wrapper {
  height: 46px;
  background: #ccc;
  margin-left: 0;
  padding-left: 0;
}
.tabBtn >>> .ant-anchor {
  height: 100%;
  font-size: 18px;
  line-height: 46px;
  padding-left: 0;
}
.tabBtn .ant-anchor-link {
  height: 46px;
  padding: 0;
  line-height: 46px;
  text-align: center;
  color: #000;
}
.isActive >>> .ant-anchor-wrapper {
  background: #fff;
  overflow: hidden;
}
.isActive >>> .ant-anchor-link {
  color: #d4000e;
  border-top: 3px solid #d4000e;
}
.tabBtn >>> .ant-anchor-link-active > .ant-anchor-link-title {
  color: #000;
}
</style>
