<template>
  <a-card class="canvas-container">
    <div
      class="toolbar"
      :style="isMobile ? [{ width: +(pdfw + 10) + 'px' }] : 'width:100%;'"
    >
      <span style="" class="percent">{{ scale * 100 + "%" }}</span>
      <a-icon
        class="zoomclass"
        title="放大"
        type="zoom-in"
        @click="
          isMobile
            ? _renderPageMobileZoom(
                renderTotalPage,
                scale + 0.25,
                rotation,
                'zoom'
              )
            : _renderPage(currentPage, scale + 0.25)
        "
        style=""
      />
      <a-icon
        class="zoomclass zoomout"
        title="缩小"
        type="zoom-out"
        @click="
          isMobile
            ? _renderPageMobileZoom(
                renderTotalPage,
                scale - 0.25,
                rotation,
                'zoom'
              )
            : _renderPage(currentPage, scale - 0.25)
        "
        style=""
      />
      <button
        v-if="!isMobile"
        class="buttonclass up"
        title="上一页"
        :disabled="currentPage === 1"
        @click="_renderPage(--currentPage, scale)"
        style=""
      >
        <a-icon type="caret-up" />
      </button>

      <p class="pagecount">
        <span class="pages">页码</span>
        <a-input-number
          v-model="jumpPage"
          @pressEnter="_renderPage(jumpPage, scale)"
        />
        <span style="" class="total" v-if="!isMobile"> of {{ total }} </span>
        <span style="" class="total" v-if="isMobile"> of {{ h5total }} </span>
      </p>

      <button
        v-if="!isMobile"
        class="buttonclass down"
        title="下一页"
        :disabled="currentPage === total"
        @click="_renderPage(++currentPage, scale)"
      >
        <a-icon type="caret-down" />
      </button>
    </div>
    <a-spin :spinning="spinning" v-if="!isMobile">
      <canvas ref="the-canvas"> </canvas>
    </a-spin>
    <van-list
      v-if="isMobile"
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="_renderPageMobile(++renderTotalPage, scale, rotation, 'load')"
    >
      <canvas
        ref="the-canvas-mobile"
        v-for="index in renderTotalPage"
        :key="index"
      >
      </canvas>
    </van-list>
  </a-card>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import PDFJS from "pdfjs-dist";
import workerSrc from "pdfjs-dist/build/pdf.worker.entry";
PDFJS.workerSrc = workerSrc;
export default {
  name: "Pdf",
  //props: ['pdfReabUrl'],
  data() {
    return {
      pdfw: 0,
      pdfH: "",
      pdfUrl: this.$route.query.pdfUrl,
      pdfDoc: null,
      h5pdfDoc: null,
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
      isMobile: false,
      scale: 0.75,
      //PC
      currentPage: 1,
      total: 1,
      spinning: false,
      jumpPage: 1,
      isBottom: false,
      isTop: false,
      //手机
      h5total: 1,
      renderTotalPage: 0,
      rotation: 0,
      loading: false,
      finished: false,
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  created() {
    this.windowWidth(this.width);
  },
  mounted() {
    const _this = this;
    //console.log(1)
    window.addEventListener(
      "mousewheel",
      this.debounce(this.handleScroll, 300),
      true
    ) ||
      window.addEventListener(
        "DOMMouseScroll",
        this.debounce(this.handleScroll, 300),
        true
      );
    window.onresize = () => {
      return (() => {
        _this.width = document.documentElement.clientWidth;
        _this.height = document.documentElement.clientHeight;
      })();
    };
  },
  watch: {
    width(val) {
      if (!this.timer) {
        this.width = val;
        this.timer = true;
        let _this = this;
        setTimeout(function () {
          _this.timer = false;
        }, 100);
      }
      this.windowWidth(val);
    },
  },
  methods: {
    debounce(func, wait) {
      let timeout;
      return function () {
        let context = this;
        let args = arguments;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(context, args);
        }, wait);
      };
    },
    handleScroll(event) {
      if (!this.isMobile) {
        const _self = this;
        if (this.height >= this.pdfH) {
          //下一页
          // console.log(event)
          if (
            (event.deltaY > 0 || event.detail > 0) &&
            this.currentPage !== this.total
          ) {
            _self._renderPage(++_self.currentPage, _self.scale);
            //}
          } else if (
            (event.deltaY < 0 || event.detail < 0) &&
            _self.currentPage !== 1
          ) {
            _self._renderPage(--_self.currentPage, _self.scale);
            //}
          }
        } else {
          if (_self.isBottom) {
            //下一页
            if (
              (event.deltaY > 0 || event.detail > 0) &&
              _self.currentPage !== _self.total
            ) {
              _self._renderPage(++_self.currentPage, _self.scale);
            }
          } else if (_self.isTop) {
            if (
              (event.deltaY < 0 || event.detail < 0) &&
              this.currentPage !== 1
            ) {
              _self._renderPage(--_self.currentPage, _self.scale);
            }
          }
          //变量scrollTop是滚动条滚动时，距离顶部的距离
          var scrollTop =
            document.documentElement.scrollTop || document.body.scrollTop;
          //变量windowHeight是可视区的高度
          var windowHeight =
            document.documentElement.clientHeight || document.body.clientHeight;
          //变量scrollHeight是滚动条的总高度
          var scrollHeight =
            document.documentElement.scrollHeight || document.body.scrollHeight;
          if (scrollTop === 0) {
            // console.log("到了顶部");
            _self.isBottom = false;
            _self.isTop = true;
          } else if (scrollTop + windowHeight == scrollHeight) {
            //到了这个就可以进行业务逻辑加载后台数据了
            // console.log("到了底部");
            _self.isBottom = true;
            _self.isTop = false;
          }
        }
      }
    },
    ...mapMutations("account", ["saveNowRouter"]),
    windowWidth(value) {
      var vm = this;
      if (!this.user || this.user === "") {
        this.$confirm({
          title: "确认提示",
          content: `登录后即可阅读，是否确认登录？`,
          okType: "warning",
          onOk() {
            vm.saveNowRouter(vm.$route);
            vm.$router.push({ name: "Login" });
          },
          onCancel() {
            vm.$router.push({ name: "index" });
          },
        });
        return;
      }
      if (
        localStorage.getItem("expireAt") &&
        new Date().getTime() >=
          new Date(localStorage.getItem("expireAt")).getTime()
      ) {
        this.$confirm({
          title: "确认提示",
          content: `登录已过期，该操作需重新登录，是否重新登录？`,
          okType: "warning",
          onOk() {
            vm.saveNowRouter(vm.$route);
            vm.$router.push({ name: "Login" });
          },
          onCancel() {
            vm.$router.push({ name: "index" });
          },
        });
        return;
      }
      const hide = this.$message.loading("加载中，请稍后..", 0);
      setTimeout(hide, 2500);

      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      if (flag) {
        this.isMobile = true;
        this.scale = 0.75;
      } else {
        this.isMobile = false;
        this.scale = 1.25;
      }
      this.width = value;
      //发布时使用 VUE_APP_API_BASE_URL
      this._loadFile(
        process.env.VUE_APP_API_BASE_URL +
          "/showvora/showPdf?path=" +
          this.pdfUrl
      );
      //\book\1e9ad9e1-fe82-4798-a6a5-d3d5e6102093
      // this._loadFile(
      //   process.env.VUE_APP_FLAG + "/showvora/showPdf?path=" + this.pdfUrl
      // );
    },
    _renderPage(currentPage, scale) {
      if (!this.isMobile) {
        this.isBottom = false;
        this.isTop = false;
        //console.clear()
        if (currentPage < 1) {
          currentPage = 1;
        }
        if (currentPage > this.total) {
          currentPage = this.total;
        }
        this.jumpPage = this.currentPage = currentPage;
        if (scale < 0.25 || scale > 2) {
          return;
        }
        this.scale = scale;
        this.spinning = true;
        let _self = this;
        this.pdfDoc
          .getPage(currentPage)
          .then((page) => {
            var viewport = page.getViewport({ scale: _self.scale });
            var canvas = _self.$refs["the-canvas"];
            //var canvas = document.getElementById("pdf-container");
            var context = canvas.getContext("2d");
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            _self.pdfw =
              viewport.width > _self.width ? viewport.width : _self.width;
            _self.pdfH = viewport.height;
            var renderContext = {
              canvasContext: context,
              viewport: viewport,
            };
            page.render(renderContext);
          })
          .finally(() => {
            _self.spinning = false;
          });
      }
    },
    _renderPageMobileZoom(renderPage, scale, rotation) {
      let _self = this;
      if (renderPage < 1) {
        renderPage = 1;
      }
      if (renderPage > _self.h5total) {
        _self.renderTotalPage = _self.h5total;
        this.finished = true;
        return;
      }
      _self.renderTotalPage = renderPage;
      if (scale) {
        if (scale < 0.25 || scale > 2) {
          return;
        }
        _self.scale = scale;
      }
      if (rotation) {
        _self.rotation = parseInt(rotation) % 360;
      }
      if (_self.h5pdfDoc !== null) {
        _self.loading = true;

        for (let index = 1; index <= renderPage; index++) {
          _self.h5pdfDoc
            .getPage(index)
            .then((page) => {
              var viewport = page.getViewport({
                scale: _self.scale,
                rotation: _self.rotation,
              });

              var canvas = _self.$refs["the-canvas-mobile"][index - 1];
              var context = canvas.getContext("2d");
              canvas.height = viewport.height;
              canvas.width = viewport.width;
              _self.pdfw =
                viewport.width > _self.width ? viewport.width : _self.width;
              _self.pdfH = viewport.height;
              var renderContext = {
                canvasContext: context,
                viewport: viewport,
              };
              page.render(renderContext);
            })
            .finally(() => {
              _self.loading = false;
            });
        }
      }
    },
    _renderPageMobile(renderPage, scale, rotation) {
      // console.log(renderPage + type);
      let _self = this;
      if (renderPage < 1) {
        renderPage = 1;
      }
      if (renderPage > _self.h5total) {
        _self.renderTotalPage = _self.h5total;
        _self.finished = true;
        return;
      }
      _self.renderTotalPage = renderPage;
      if (scale) {
        if (scale < 0.25 || scale > 2) {
          return;
        }
        _self.scale = scale;
      }
      if (rotation) {
        _self.rotation = parseInt(rotation) % 360;
      }
      if (_self.h5pdfDoc !== null) {
        _self.loading = true;
        _self.h5pdfDoc
          .getPage(renderPage)
          .then((page) => {
            var viewport = page.getViewport({
              scale: _self.scale,
              rotation: _self.rotation,
            });

            var canvas = _self.$refs["the-canvas-mobile"][renderPage - 1];
            var context = canvas.getContext("2d");
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            _self.pdfw =
              viewport.width > _self.width ? viewport.width : _self.width;
            _self.pdfH = viewport.height;
            var renderContext = {
              canvasContext: context,
              viewport: viewport,
            };
            page.render(renderContext);
          })
          .finally(() => {
            _self.loading = false;
          });
      }
    },
    _loadFile(url) {
      let _self = this;
      if (!_self.isMobile) {
        const CMAP_URL =
          "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.4.456/cmaps/";
        PDFJS.getDocument({
          url: url,
          rangeChunkSize: 1024 * 1024,
          cMapUrl: CMAP_URL,
          cMapPacked: true,
        }).promise.then((pdf) => {
          _self.pdfDoc = pdf;
          _self.total = pdf.numPages;
          if (pdf !== null) {
            _self.$nextTick(() => {
              _self._renderPage(_self.currentPage, _self.scale);
            });
          }
        });
      } else if (_self.isMobile) {
        const CMAP_URL =
          "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.4.456/cmaps/";
        PDFJS.getDocument({
          url: url,
          rangeChunkSize: 1024 * 1024,
          cMapUrl: CMAP_URL,
          cMapPacked: true,
        }).promise.then((pdf) => {
          _self.h5pdfDoc = pdf;
          _self.h5total = pdf.numPages;
          if (pdf !== null) {
            _self.$nextTick(() => {
              _self._renderPageMobile(
                1,
                _self.scale,
                _self.rotation,
                "nextTick"
              );
            });
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.canvas-container {
  text-align: center;
  border: 0 !important;
  background-color: rgb(236, 219, 219);
}
.canvas-container .toolbar {
  /* width: 100%; */
  height: 32px;
  z-index: 9999;
  text-align: initial;
  background-image: url(~@/assets/images/texture.png),
    linear-gradient(hsla(0, 0%, 32%, 0.99), hsla(0, 0%, 27%, 0.95));
}
.canvas-container >>> .ant-spin-nested-loading {
  margin-top: 10px;
  overflow-y: auto;
}
.percent {
  color: white;
  width: 3%;
  margin-left: 38%;
}
.canvas-container i.zoomclass {
  line-height: 32px;
  color: white;
  margin-right: 0.5%;
}
.canvas-container i.zoomclass.zoomout {
  margin-right: 0.7%;
}
.canvas-container .buttonclass {
  display: contents;
  cursor: pointer;
}
.canvas-container .buttonclass.up i {
  margin-right: 0.1%;
  margin-left: 0.5%;
}
.buttonclass i {
  color: white;
}
.canvas-container span {
  line-height: 32px;
}
.pagecount {
  display: inline-block;
  height: 96%;
  margin-bottom: 0;
  margin-left: 1%;
}
.pagecount .ant-input-number {
  height: 63%;
  border: 1px solid transparent;
  background-color: hsla(0, 0%, 100%, 0.09);
  background-image: linear-gradient(
    hsla(0, 0%, 100%, 0.05),
    hsla(0, 0%, 100%, 0)
  );
  background-clip: padding-box;
  border: 1px solid hsla(0, 0%, 0%, 0.35);
  border-color: hsla(0, 0%, 0%, 0.32) hsla(0, 0%, 0%, 0.38)
    hsla(0, 0%, 0%, 0.42);
  box-shadow: 0 1px 0 hsl(0deg 0% 0% / 5%) inset, 0 1px 0 hsl(0deg 0% 100% / 5%);
  color: hsl(0, 0%, 95%);
  font-size: 14px;
  line-height: 14px;
  outline-style: none;
  transition-duration: 150ms;
  transition-timing-function: ease;
}
.pagecount >>> .ant-input-number-handler-wrap {
  display: none;
}
.pagecount >>> .ant-input-number-input-wrap input {
  height: 100%;
  text-align: right;
  display: block;
}
.pagecount span.total {
  width: 60px;
  height: 24px;
  color: white;
  background-color: hsla(0, 0%, 100%, 0);
  background-image: linear-gradient(
    hsla(0, 0%, 100%, 0.05),
    hsla(0, 0%, 100%, 0)
  );
  background-clip: padding-box;
}
.pagecount span.pages {
  width: 30px;
  height: 24px;
  color: white;
  background-color: hsla(0, 0%, 100%, 0);
  background-image: linear-gradient(
    hsla(0, 0%, 100%, 0.05),
    hsla(0, 0%, 100%, 0)
  );
  background-clip: padding-box;
}
.canvas-container >>> .ant-card-body {
  padding: 0px;
}
.canvas-container canvas {
  box-shadow: 0 0 5px 1px #999;
}
@media screen and (max-width: 1300px) {
  .canvas-container .buttonclass.up i {
    margin-right: 0.3%;
    margin-left: 0.5%;
  }
}
@media screen and (max-width: 900px) {
  .percent {
    width: 6%;
  }
  .canvas-container .buttonclass.up i {
    margin-right: 1%;
    margin-left: 0.5%;
  }
}
@media screen and (max-width: 600px) {
  .percent {
    margin-left: 20%;
    width: 10%;
  }
  .canvas-container i.zoomclass.zoomout {
    margin-right: 2%;
  }
  .canvas-container .buttonclass.up i {
    margin-right: 2.2%;
    margin-left: 0.5%;
  }
}
@media screen and (max-width: 350px) {
  .percent {
    margin-left: 15%;
    width: 10%;
  }
  .canvas-container i.zoomclass {
    margin-right: 4%;
  }
  .canvas-container .buttonclass.up i {
    margin-right: 0.3%;
    margin-left: 0.5%;
  }
}
</style>


