<template>
  <div class="audio-details">
    <div class="details-con">
      <div class="container">
        <div class="main-item position">
          <a @click="goToView('/home/index')">首页</a>
          &gt;
          <span>音频详情</span>
        </div>
        <div class="main-item rec">
          <div class="title">
            <span
              ><i></i>
              {{ detail.name }}
            </span>
          </div>
          <div class="show">
            <a-player
              ref="audioPlayer"
              :music="music"
              theme="#1057d1"
              :showLrc="false"
              v-if="flag"
            ></a-player>
          </div>
          <a-card :bordered="false" class="basic-info">
            <span slot="title">基本信息</span>
            <a-descriptions>
              <a-descriptions-item label="音频名称">
                {{ detail.name }}
              </a-descriptions-item>
              <a-descriptions-item label="音频作者">
                {{ detail.author }}
              </a-descriptions-item>
              <a-descriptions-item label="音频类别">
                {{ detail.audiotype }}
              </a-descriptions-item>
              <a-descriptions-item label="音频大小">
                {{ detail.audiosize }}
              </a-descriptions-item>
              <a-descriptions-item label="音频时长">
                {{ detail.audiotime }}
              </a-descriptions-item>
              <a-descriptions-item label="音频精度">
                {{ detail.type }}
              </a-descriptions-item>
              <a-descriptions-item label="来源">
                {{ detail.source }}
              </a-descriptions-item>
              <a-descriptions-item label="出版时间">
                {{ detail.dateissued }}
              </a-descriptions-item>
              <a-descriptions-item label="关键词">
                {{ detail.keywords }}
              </a-descriptions-item>
              <a-descriptions-item label="出版时间">
                {{ detail.dateissued }}
              </a-descriptions-item>
              <a-descriptions-item label="所属部门">
                {{ detail.name }}
              </a-descriptions-item>
              <a-descriptions-item label="所属业务应用库">
                {{ detail.sys_fld_ldbid }}
              </a-descriptions-item>
              <a-descriptions-item label="分类">
                {{ detail.sys_fld_classfication }}
              </a-descriptions-item>
            </a-descriptions>
          </a-card>
          <a-card :bordered="false" class="brief" v-if="detail.description">
            <span slot="title">音频简介</span>
            <p>{{ detail.description }}</p>
          </a-card>
          <a-card :bordered="false">
            <a class="btn-collect" @click="favoritesFun(detail)"
              ><a-icon
                type="heart"
                class="hertClass"
                :class="{ isFavorites: favorites }"
              />收藏</a
            >
          </a-card>
        </div>
        <div class="main-item rec">
          <div class="title">
            <span><i></i>推荐音频</span>
          </div>
          <a-list :data-source="dataResource" class="listStype">
            <a-list-item
              slot="renderItem"
              :key="item.doi"
              slot-scope="item"
              class="pic"
              :title="item.name"
              style="width: 232px"
              @click="goDetail('AudioDetail', item.doi)"
            >
              <div class="content-book content-play">
                <div class="imgbox">
                  <img
                    :src="item.imgurl"
                    @click="goDetail('AudioDetail', item.sys_fld_doi)"
                  />
                  <div
                    class="icon-play"
                    @click="goDetail('AudioDetail', item.sys_fld_doi)"
                  >
                    <img src="~@/assets/images/btn-play.png" />
                  </div>
                </div>
                <div
                  class="text"
                  @click="goDetail('AudioDetail', item.sys_fld_doi)"
                >
                  <h4>{{ item.name }}</h4>
                  <p>{{ item.source }}</p>
                </div>
              </div>
              <!-- <a-card hoverable style="width: 232px" class="imgbox">
                <img slot="cover" alt="example" :src="item.imgurl" />
                <a slot="cover" class="icon-play"
                  ><img src="~@/assets/images/btn-play.png"
                /></a>
                <span slot="cover"></span>
                <a-card-meta :title="item.name">
                  <template slot="description">
                    <p>{{ item.issuedep }}</p>
                  </template>
                </a-card-meta>
              </a-card> -->
            </a-list-item>
          </a-list>
          <div class="more">
            <a @click="goToView('/home/bookIndex')">查看更多</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueAplayer from "vue-aplayer";
import { replacePath, replaceAudioPath,isMobile } from "@/utils/util";
import * as audioApi from "@/services/audio";
import { recommendDoiList } from "@/config/constConfig";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "AudioDetails",
  components: {
    "a-player": VueAplayer,
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  watch: {
    $route(to) {
      this.doi = to.params.doi;
      this.initDetail();
      this.getIsFav();
    },
  },
  data() {
    return {
      favorites: false,
      dataResource: [],
      currentPage: ["AudioList"],
      flag: false,
      music: {
        title: "音频播放",
        artist: "作者",
        src: "",
        pic: "",
      },
      doi: this.$route.params.doi,
      detail: {},
    };
  },
  created() {
    this.Mobile()
    this.initDetail();
    this.initRecommend(recommendDoiList.detailAudioDoi);
    this.getIsFav();
  },
  methods: {
    Mobile(){
      if(isMobile()){
        this.$router.push({ name: 'MobileAudioDetail', params: { doi: this.$route.params.doi } });
      }
    },
    ...mapMutations("account", ["saveNowRouter"]),
    //获取是否已经收藏
    getIsFav() {
      if (!this.user || this.user === "") {
        return;
      }
      let obj = {
        doi: this.$route.params.doi,
        restype: "6",
        username: this.user,
      };
      audioApi.getIsFav(obj).then((res) => {
        if (res.success) {
          this.favorites = true;
        } else {
          this.favorites = false;
        }
      });
    },
    //收藏/取消收藏
    favoritesFun(item) {
      var vm = this
      if (!this.user || this.user === "") {
        this.$confirm({
          title: "确认提示",
          content: `收藏需要先登录，是否确认登录？`,
          okType: "warning",
          onOk() {
            vm.saveNowRouter(vm.$route)
            vm.$router.push({ name: "Login"});
          },
          onCancel() {
            console.log('取消')
          }
        });
        // this.$notification["warning"]({
        //   message: "警告",
        //   description: "请先登录!",
        //   placement: "topLeft",
        //   style: {
        //     width: "500px",
        //   },
        // });
        return;
      }
      if (
        localStorage.getItem("expireAt") &&
        new Date().getTime() >=
          new Date(localStorage.getItem("expireAt")).getTime()
      ) {
        this.$confirm({
          title: "确认提示",
          content: `登录已过期，该操作需重新登录，是否重新登录？`,
          okType: "warning",
          onOk() {
            vm.saveNowRouter(vm.$route)
            vm.$router.push({ name: "Login"});
          },
          onCancel() {
            console.log('取消')
          }
        });
        // this.$notification["warning"]({
        //   message: "警告",
        //   description: "toiken已过期，请重新登录再进行操作!",
        //   placement: "topLeft",
        //   style: {
        //     width: "500px",
        //   },
        // });
        return;
      }
      let obj = {
        booktype: "6",
        doi: this.$route.params.doi,
        name: item.name,
        operator: this.user,
      };
      audioApi.addFavoriteData(obj).then((res) => {
        if (res.success) {
          this.$message.success(res.message);
          // this.$notification["success"]({
          //   message: "成功",
          //   description: res.message,
          //   placement: "topLeft",
          //   style: {
          //     width: "500px",
          //   },
          // });
          this.favorites = !this.favorites;
        } else {
          this.$message.error(res.message);
          // this.$notification["warning"]({
          //   message: "警告",
          //   description: res.message,
          //   placement: "topLeft",
          //   style: {
          //     width: "500px",
          //   },
          // });
        }
      });
    },
    goDetail(name, doi) {
      this.$router.push({ name: name, params: { doi: doi } });
    },
    goToView(path) {
      this.$router.push({ path: path });
    },
    initRecommend(typeDoi) {
      audioApi
        .getRecommendByType({ page: 1, pageSize: 4, type: typeDoi })
        .then((res) => {
          const { success } = res;
          if (success) {
            const {
              data: { list },
            } = res;
            this.dataResource = list.map((e) => {
              return {
                doi: e.sys_fld_doi,
                name: e.name,
                imgurl: replacePath(e.sys_fld_coverpath, "0", "1"),
                source: e.source,
              };
            });
          } else {
            this.dataResource = [];
          }
        });
    },
    //浏览记录
    addLog(item) {
      if (!this.user || this.user === "") {
        return;
      }
      if (
        localStorage.getItem("expireAt") &&
        new Date().getTime() >=
          new Date(localStorage.getItem("expireAt")).getTime()
      ) {
        return;
      }
      let obj = {
        resourcetype: "6",
        resourcedoi: this.$route.params.doi,
        resourcename: item.name,
        username: this.user,
      };
      audioApi.addLog(obj);
    },
    initDetail() {
      audioApi.getInfo(this.doi).then((res) => {
        const { success } = res;
        if (success) {
          this.flag = true;
          const {
            data: { data },
          } = res;
          this.detail = data;
          this.music.title = data.name;
          this.music.artist = data.author;
          this.music.src = replaceAudioPath(data.sys_fld_filepath);
          this.music.pic = replacePath(data.sys_fld_coverpath);
          this.addLog(this.detail);
        } else {
          this.detail = {};
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  background: #fff;
  padding: 0 20px 0 20px;
  margin-top: 20px;
  h3 {
    font-size: 20px;
    margin-top: 10px;
  }
}
// @import "./details.less";
</style>
<style scoped>
.main-item >>> .ant-card-head {
  padding: 0 !important;
}
</style>
<style lang="less" scoped>
img {
  cursor: pointer;
}
.main-item {
  .more {
    text-align: center;
    padding: 20px 0;
    a {
      padding: 10px 42px;
      font-size: 16px;
      color: #fff;
      background: #ea7b19;
      border-radius: 22px;
    }
  }
}
.content-book {
  width: 116px;
  height: 182px;
  float: left;
  margin-right: 12.5%;
  padding-top: 20px;
  margin-bottom: 50px;
  text-align: left;
  .nameClass {
    width: 100%;
    font-size: 16px;
    padding-top: 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: middle;
    text-align: center;
  }
  .nameClass:hover {
    color: #d4000e;
    cursor: pointer;
  }
  .companyName {
    font-size: 14px;
    color: #8d8d8d;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: middle;
  }
  .imgbox {
    position: relative;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
    .icon-play {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 70px;
      height: 70px;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
}
.content-book:nth-child(4n + 0) {
  margin-right: 0;
}
.content-play {
  width: 245px;
  height: 144px;
  border: 2px solid #e9e9e9;
  margin-right: 15px;
  padding-top: 0;
  margin-top: 15px;
  margin-bottom: 110px;
}
.content-play:nth-child(4n + 0) {
  margin-right: 15px;
}
.content-play:nth-child(3n + 0) {
  margin-right: 0;
}
.text {
  text-align: center;
  border: 1px solid #e8e8e8;
  h4 {
    width: 100%;
    font-size: 16px;
    padding: 15px 0;
    border-bottom: 2px solid #e9e9e9;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: middle;
  }
  h4:hover {
    cursor: pointer;
    color: #d4000e;
  }
  p {
    font-size: 14px;
    color: #8d8d8d;
    line-height: 32px;
    height: 22px;
  }
}
.btn-collect {
  float: right;
  width: 66px;
  font-size: 16px;
  padding: 15px 0;
  .hertClass {
    margin-right: 5px;
  }
  .isFavorites {
    color: #d4000e;
  }
}
</style>
<style scoped>
.container >>> .ant-card-cover {
  width: 100%;
  height: 130px;
}
.container >>> .ant-card-cover img {
  height: 100%;
}
.container >>> .ant-list-items {
  overflow: hidden;
}
.container >>> .ant-list-items li {
  float: left;
  margin-left: 47px;
  zoom: 1;
}
.listStype >>> .ant-card-body {
  padding: 24px 0;
  text-align: center;
}
.listStype >>> .ant-card-body .ant-card-meta-title {
  border-bottom: 1px solid #ccc;
  height: 41px;
}
.container >>> .ant-list-split .ant-list-item {
  border-bottom: none !important;
}
</style>