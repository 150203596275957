<template>
  <div class="content-wrap">
    <div class="user-pic">
      <div class="newcontainer">
        <div class="row">
          <div class="col-xs-12">
            <div class="user-con">
              <img src="~@/assets/images/user-pic.png" alt="" />
              <p>{{ user }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="user-item">
      <div class="newcontainer">
        <div class="row">
          <div class="col-xs-12">
            <ul>
              <li>
                <a class="my-collect" @click="goDetail('MyCollect')"
                  ><i></i>我的收藏<span></span
                ></a>
              </li>
              <li>
                <a class="my-footprint" @click="goDetail('Footprint')"
                  ><i></i>我的足迹<span></span
                ></a>
              </li>
              <li>
                <a class="edit-password" @click="goDetail('UpdatePassword')"
                  ><i></i>修改密码<span></span
                ></a>
              </li>
              <li>
                <a class="exit" @click="logout()"
                  ><i></i>退出登录<span></span
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isMobile } from "@/utils/util";
import { mapGetters, mapMutations } from "vuex";
import { removeAuthorization } from "@/utils/request";
export default {
  name: "mobileUserCenter",
  data() {
    return {};
  },
  watch: {},
  computed: {
    ...mapGetters("account", ["user"]),
  },
  created() {
    //this.onLoad(1);
    this.Mobile();
    this.bindActive();
  },
  methods: {
    ...mapMutations("account", ["removeUser", "removeRoles", "removeExpireAt"]),
    Mobile() {
      if (!isMobile()) {
        this.$router.push({ name: "userCenter" });
      }
    },
    bindActive() {
      this.$emit("bindActive", "mobileUserCenter");
    },
    logout() {
      this.removeUser();
      this.removeRoles();
      this.removeExpireAt();
      removeAuthorization();
      this.$router.push({ name: "mobileLogin" });
    },
    goDetail(name) {
      this.$router.push({
        name: name,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.content-wrap {
  background-color: #fff !important;
  width: 100%;
  padding-top: 200px;
  background: url(~@/assets/images/user-center-bg.png) no-repeat center top;
  background-size: contain;
  .user-pic {
    .newcontainer {
      padding-right: 0.3rem;
      padding-left: 0.3rem;
      margin-right: auto;
      margin-left: auto;
      .row {
        margin-right: -0.3rem;
        margin-left: -0.3rem;
        .col-xs-12 {
          width: 100%;
          // float: left;
          // position: relative;
          min-height: 1px;
          padding-right: 0.3rem;
          padding-left: 0.3rem;
          .user-con {
            text-align: center;
            background: #fff;
            padding-bottom: 0.4rem;
            border-radius: 0.24rem;
            box-shadow: 0 2px 8px rgba(222, 71, 66, 0.4);
            img {
              width: 30%;
              height: auto;
              margin-top: -1.24rem;
              border-radius: 50%;
              border: 0.06rem solid #fff;
              box-shadow: 0 2px 8px rgba(222, 71, 66, 0.4);
            }
            p {
              padding-top: 0.2rem;
              font-size: 0.28rem;
            }
            padding-top: 0.2rem;
            font-size: 0.32rem;
          }
        }
      }
    }
  }
  .user-item {
    padding-top: 0.3rem;
    .newcontainer {
      padding-right: 0.3rem;
      padding-left: 0.3rem;
      margin-right: auto;
      margin-left: auto;
      .row {
        margin-right: -0.3rem;
        margin-left: -0.3rem;
        .col-xs-12 {
          width: 100%;
          // float: left;
          // position: relative;
          min-height: 1px;
          padding-right: 0.3rem;
          padding-left: 0.3rem;
          li {
            padding: 0.3rem 0;
            a {
              width: 100%;
              font-size: 0.28rem;
              color: #666666;
              i {
                margin-top: -0.06rem;
                margin-right: 0.2rem;
                vertical-align: middle;
              }
              span {
                float: right;
                width: 0.2rem;
                height: 0.36rem;
                background: url(~@/assets/images/arrow-r.png) no-repeat;
                background-size: 100% auto;
                vertical-align: middle;
                margin-top: 0.06rem;
              }
            }
            .my-collect {
              i {
                width: 0.36rem;
                height: 0.36rem;
                background: url(~@/assets/images/icon-collect-2.png) no-repeat;
                background-size: 100% auto;
              }
            }
            .my-footprint {
              i {
                width: 0.36rem;
                height: 0.4rem;
                background: url(~@/assets/images/icon-footprint.png) no-repeat;
                background-size: 100% auto;
              }
            }
            .edit-password {
              i {
                width: 0.36rem;
                height: 0.4rem;
                background: url(~@/assets/images/icon-edit.png) no-repeat;
                background-size: 100% auto;
              }
            }
            .exit {
              i {
                width: 0.36rem;
                height: 0.4rem;
                background: url(~@/assets/images/icon-exit.png) no-repeat;
                background-size: 100% auto;
              }
            }
          }
        }
      }
    }
  }
}
</style>
