<template>
  <div>
    <div class="nav-ul">
      <!--tab导航-->
      <ul id="myTabs" class="nav nav-tabs" role="tablist">
        <li
          v-for="(item, index) in TypeList"
          :class="item.class"
          :key="index"
          @click="TypeClick(item)"
        >
          <a>{{ item.name }}</a>
        </li>
      </ul>
    </div>
    <div class="content-wrap">
      <!-- <div class="total">
            <p>
              共检索<span>{{ total }}</span
              >条数据
            </p>
          </div> -->
      <div style="background-color: #fff">
        <div class="themediv">
          <ul class="themeul">
            <li
              v-for="item in ThemeClassList"
              :class="item.class"
              :key="item.themeid"
              @click="ThemeClick(item)"
            >
              <a>{{ item.themename }}</a>
            </li>
          </ul>
        </div>
        <div id="myTabContent" class="tab-content">
          <div
            role="tabpanel"
            class="tab-pane fade active in"
            id="recs"
            aria-labelledby="recs-tab"
          >
            <!--图书-->
            <div class="con-item book" v-if="type === 'book'">
              <div class="newscontainer">
                <van-list
                  v-model="vanListLoading"
                  :finished="finished"
                  :finished-text="finishedText"
                  @load="onLoad('book')"
                >
                  <li v-for="item in list" :key="item.doi">
                    <div class="row">
                      <div class="col-xs-4">
                        <a @click="goRouter('MobileBookDetail', item.doi)"
                          ><img :src="item.imgurl" alt=""
                        /></a>
                      </div>
                      <div class="col-xs-8">
                        <div class="keys-text">
                          <div class="txt">
                            <h4
                              :title="item.title"
                              v-html="item.name"
                              @click="goRouter('MobileBookDetail', item.doi)"
                            ></h4>
                            <p class="brief">{{ item.author }} 著</p>
                          </div>
                          <p class="author">
                            <span>{{ item.issuedep }}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                </van-list>
              </div>
            </div>
            <!--听书-->
            <div class="con-item audio" v-if="type === 'audio'">
              <div class="newscontainer">
                <van-list
                  v-model="vanListLoading"
                  :finished="finished"
                  :finished-text="finishedText"
                  @load="onLoad('audio')"
                >
                  <li v-for="item in list" :key="item.doi">
                    <!-- <div class="row">
                    <div class="col-xs-4">
                      <div class="pic">
                        <img :src="item.imgurl" alt="" />
                        <span></span>
                      </div>
                    </div>
                    <div class="col-xs-8">
                      <div class="keys-text">
                        <h4 :title="item.title" v-html="item.name"></h4>
                        <p class="brief">{{ item.source }}</p>
                      </div>
                    </div>
                  </div> -->
                    <div class="col-xs-4">
                      <div
                        class="pic"
                        @click="goRouter('MobileAudioDetail', item.doi)"
                      >
                        <img :src="item.imgurl" alt="" /><span></span>
                      </div>
                      <div class="keys-text">
                        <h4
                          :title="item.title"
                          v-html="item.name"
                          @click="goRouter('MobileAudioDetail', item.doi)"
                        ></h4>
                      </div>
                    </div>
                  </li>
                </van-list>
              </div>
            </div>
            <!--视频-->
            <div class="con-item video" v-if="type === 'video'">
              <div class="newscontainer">
                <van-list
                  v-model="vanListLoading"
                  :finished="finished"
                  :finished-text="finishedText"
                  @load="onLoad('video')"
                >
                  <li v-for="item in list" :key="item.doi">
                    <div class="row">
                      <div class="col-xs-12">
                        <div
                          class="pic"
                          @click="goRouter('MobileVideoDetail', item.doi)"
                        >
                          <img :src="item.imgurl" alt="" />
                          <span></span>
                        </div>
                        <h5>
                          <a
                            :title="item.title"
                            v-html="item.name"
                            @click="goRouter('MobileVideoDetail', item.doi)"
                          ></a>
                        </h5>
                        <!-- <p>{{ item.source }}</p> -->
                      </div>
                    </div>
                  </li>
                </van-list>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Apireadings from "@/services/readings";
import { replacePath, replaceNew, isMobile } from "@/utils/util";
const orderTheme = (list) => {
  return list.sort((a, b) => a.ordernum - b.ordernum);
};
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "MobileSearch",
  //   components: { swiper, swiperSlide, PdfStreamRead },
  props: ["keywords"],
  data() {
    return {
      ThemeClassList: [],
      TypeList: [
        {
          name: "图书",
          type: "book",
          class: "active",
        },
        {
          name: "听书",
          type: "audio",
          class: "",
        },
        {
          name: "视频",
          type: "video",
          class: "",
        },
      ],
      type: "book",
      classid: "",
      list: [],
      vanListLoading: false, // 加载状态
      finished: false, // 是否加载结束
      finishedText: "已加载全部", // 加载完成后的提示文案
      pages: 0, // 页数
      pnums: 10, // 条数
      keyword: "",
      total: 0,
    };
  },
  watch: {
    keywords: {
      handler(val) {
        this.list = [];
        this.vanListLoading = false;
        this.finished = false;
        this.pages = 0;
        this.pnums = 10;
        this.total = 0;
        this.keyword = val;
        this.onLoad(this.type);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters("account", ["user"],
    //this.bindActive(),
    ),
  },
  created() {
    this.Mobile();
    //this.onLoad('book');
    this.initTheme("0");
    this.bindActive()
  },
  methods: {
    ...mapMutations("account", ["saveNowRouter"]),
    Mobile() {
      if (!isMobile()) {
        this.$router.push({ name: "bookIndex" });
      }
    },
    bindActive(){
			this.$emit("bindActive", "mobileSearch");
		},
    goRouter(name, query) {
      if(name==='MobileVideoDetail'||name==='MobileAudioDetail'){
        var vm = this;
      if (!this.user || this.user === "") {
        this.$confirm({
          title: "确认提示",
          content: `登录后即可查看，是否确认登录？`,
          okType: "warning",
          onOk() {
            vm.saveNowRouter(vm.$route);
            vm.$router.push({ name: "Login" });
          },
          onCancel() {
            console.log("取消");
          },
        });
        return;
      }
      if (
        localStorage.getItem("expireAt") &&
        new Date().getTime() >=
          new Date(localStorage.getItem("expireAt")).getTime()
      ) {
        this.$confirm({
          title: "确认提示",
          content: `登录已过期，该操作需重新登录，是否重新登录？`,
          okType: "warning",
          onOk() {
            vm.saveNowRouter(vm.$route);
            vm.$router.push({ name: "Login" });
          },
          onCancel() {
            console.log("取消");
          },
        });
        return;
      }
      }
      this.$router.push({
        name: name,
        params: {
          doi: query,
        },
      });
    },
    initTheme(pid) {
      Apireadings.getThemeList(pid).then((res) => {
        let {
          success,
          data: { list },
        } = res;
        if (success) {
          let themeList = orderTheme(list);
          themeList.unshift({
            childlist: [],
            ordernum: 1,
            parentid: 0,
            themeid: "",
            themename: "全部",
          });
          this.ThemeClassList = themeList.map((e) => {
            return {
              themeid: e.themeid,
              themename: e.themename,
              parentid: e.parentid,
              class: e.themename === "全部" ? "active" : "",
            };
          });
        } else {
          this.ThemeClassList = [];
        }
      });
    },
    TypeClick(item) {
      this.TypeList.forEach((element) => {
        element.class = "";
      });
      item.class = "active";
      this.type = item.type;
      this.list = [];
      this.vanListLoading = false;
      this.finished = false;
      this.total = 0;
      this.pages = 0;
      this.pnums = 10;
      this.onLoad(this.type);
    },
    ThemeClick(item) {
      this.ThemeClassList.forEach((element) => {
        element.class = "";
      });
      item.class = "active";
      this.classid = item.themeid;
      this.list = [];
      this.vanListLoading = false;
      this.finished = false;
      this.total = 0;
      this.pages = 0;
      this.pnums = 10;
      // console.log(this.pages);
      this.onLoad(this.type);
    },
    onLoad(type) {
      this.pages += 1;
      let params = {
        page: this.pages,
        pageSize: this.pnums,
        keyword: this.keyword,
        classid: this.classid,
      };

      if (type === "book") {
        Apireadings.getBookList(params).then((res) => {
          if (res.success) {
            this.vanListLoading = false;
            //console.log(res);
            const {
              data: { list, pageCount, count },
            } = res;
            this.total = count;
            let concatlist = list.map((e) => {
              return {
                name: replaceNew(e.name, this.keyword),
                title: e.name,
                author: e.author,
                digest: e.digest,
                imgurl: replacePath(e.sys_fld_coverpath, "0", "1"),
                issuedep: e.issuedep,
                doi: e.sys_fld_doi,
              };
            });
            this.list = this.list.concat(concatlist);
            // console.log(this.pages+'pages')
            // console.log(pageCount+'pageCount')
            if (this.pages >= pageCount) {
              this.finished = true;
            }
          } else {
            this.list = [];
            this.vanListLoading = false;
            this.finished = true;
            this.total = 0;
            this.pages = 0;
            this.pnums = 10;
          }
        });
      } else if (type === "video") {
        Apireadings.getVideoList(params).then((res) => {
          if (res.success) {
            this.vanListLoading = false;
            const {
              data: { list, pageCount, count },
            } = res;
            this.total = count;
            let concatlist = list.map((e) => {
              return {
                name: replaceNew(e.name, this.keyword),
                title: e.name,
                source: e.source,
                imgurl: replacePath(e.sys_fld_coverpath, "0", "1"),
                doi: e.sys_fld_doi,
              };
            });
            this.list = this.list.concat(concatlist);
            if (this.pages >= pageCount) {
              this.finished = true;
            }
          } else {
            this.list = [];
            this.vanListLoading = false;
            this.finished = true;
            this.total = 0;
            this.pages = 0;
            this.pnums = 10;
          }
        });
      } else {
        Apireadings.getAudioList(params).then((res) => {
          if (res.success) {
            this.vanListLoading = false;
            const {
              data: { list, pageCount, count },
            } = res;
            this.total = count;
            let concatlist = list.map((e) => {
              return {
                name: replaceNew(e.name, this.keyword),
                title: e.name,
                source: e.source,
                imgurl: replacePath(e.sys_fld_coverpath, "0", "1"),
                doi: e.sys_fld_doi,
              };
            });
            this.list = this.list.concat(concatlist);
            if (this.pages >= pageCount) {
              this.finished = true;
            }
          } else {
            this.list = [];
            this.vanListLoading = false;
            this.finished = true;
            this.total = 0;
            this.pages = 0;
            this.pnums = 10;
          }
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.nav-ul {
  position: fixed;
  width: 100%;
  top: 1.2rem;
  z-index: 1;
  .nav {
    background: #fff;
    // padding: 0.12rem 0;
	height: 0.84rem;
    // height: 1rem;
    // border-bottom: 1px solid #e9e9e9;
  }
  .nav-tabs > li {
    float: left;
    width: 33%;
    text-align: center;
    margin-top: 0.1rem;
	margin-bottom: 0.1rem;
    line-height: initial;
    a {
      display: inline-block;
      font-size: 0.28rem;
      color: #666666;
      width: 70%;
      padding: 5px 0;
    //    border-bottom: 2px solid;
      border-color: transparent;
    }
  }
  .nav-tabs > li.active > a,
  .nav-tabs > li.active > a:focus,
  .nav-tabs > li.active > a:hover {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid #d4000e;
    color: #1d1d1d;
    font-weight: bold;
  }
}
.content-wrap {
  padding-bottom: 1rem;
  margin-top: 2.1rem;
  .total {
    background: #fff;
    margin-top: 5px;
    p {
      font-size: 0.24rem;
      text-align: left;
      padding-left: 0.3rem;
      margin-bottom: 0;
      span {
        color: #d4000e;
      }
    }
  }
  .themediv {
    top: 2.3rem;
    position: fixed;
    width: 25%;
    float: left;
    background: #fff;
    overflow: auto;
    height: 77vh;
    .themeul {
      li {
        border-bottom: 1px solid #e9e9e9;
        min-height: 0.64rem;
        height: auto;
        padding: 0.2rem;
        a {
          width: 100%;
          text-align: center;
          height: auto;
          margin: auto;
          font-size: 0.24rem;
          word-wrap: break-word;
        }
      }
      li.active {
        border-left: 0.05rem solid #d4000e;
        background-color: #ffe8e6;
        font-weight: bolder;
      }
    }
  }
  #myTabContent {
    width: 75%;
    margin-left: 25%;
    .con-item {
      //margin-top: 0.1rem;
      // padding: 20px 0;
      // border-top: 2px solid #e9e9e9;
      background: #f0f2f5;
      // ul {
      li {
        padding: 0.2rem;
        background-color: #fff;
        // border-bottom: 2px solid #e9e9e9;
        border: 1px solid #e9e9e9;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.175);
        margin-bottom: 0.2rem;
      }
      // li:first-child {
      //   padding: 0 0 0.4rem 0;
      //   border-bottom: 2px solid #e9e9e9;
      // }
    }
    .book {
      .newscontainer {
        padding: 0.2rem 0.2rem 0 0.2rem;
        margin-right: auto;
        margin-left: auto;
        .col-xs-8 {
          display: -webkit-box;
          margin-left: 2%;
          width: 55%;
          .keys-text {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-box-flex: 1;
            -webkit-box-pack: justify;
            padding-bottom: 0.1rem;
            .txt {
              h4 {
                width: 100%;
                font-size: 0.28rem;
                line-height: 0.48rem;
                font-weight: bold;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                // -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                margin-bottom: 0;
              }
              .brief {
                margin-top: 0.1rem;
                font-size: 0.2rem;
                color: #787878;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                margin-bottom: 0;
              }
            }
            .author {
              color: #333333;
              margin-top: 0.2rem;
              margin-bottom: 0;
              span {
                margin-right: 0.2rem;
              }
            }
            p {
              margin-bottom: 0;
            }
          }
        }
        .row {
          display: -webkit-box;
          margin-right: -0.3rem;
          margin-left: -0.3rem;
        }
        .col-xs-4 {
          //padding-top: 0.1rem;
          padding-right: 0.1rem;
          padding-left: 0.3rem;
          width: 2.1rem;
          img {
            background-size: 100% auto;
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
            width: 1.7rem;
            height: 2.4rem;
          }
        }
      }
    }

    .audio {
      .newscontainer {
        padding: 0.2rem 0.2rem 0 0.2rem;
        margin-right: auto;
        margin-left: auto;
        .van-list {
          li {
            width: 45%;
            display: -webkit-inline-box;
            // margin-left: 0.1rem;
            // margin-right: 0.16rem;
            margin: 0px 0.16rem 0.3rem 0.1rem;
            border-top: none;
            padding: 0;
          }
          // li:nth-child(-n + 2) {
          //   border-top: none;
          //   padding: 0;
          // }
        }

        .col-xs-4 {
          padding-right: 0px;
          //   padding-left: 0.3rem;
          width: 100%;
          border-left: 2px solid #e9e9e9;
          border-right: 2px solid #e9e9e9;
          border-top: 2px solid #e9e9e9;
          box-shadow: 0 3px 5px rgba(0, 0, 0, 0.175);
          .pic {
            position: relative;
            img {
              max-width: 100%;
              width: 100%;
              height: 1.4rem;
              min-height: 1.4rem;
            }
            span {
              position: absolute;
              left: 50%;
              top: 50%;
              -webkit-transform: translate(-50%, -50%);
              -moz-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
              width: 0.8rem;
              height: 0.8rem;
              background: url(~@/assets/images/icon-audio.png) no-repeat;
              background-size: 100% auto;
              cursor: pointer;
            }
          }
          .keys-text {
            h4 {
              margin-bottom: 0.1rem;
              margin-top: 0.1rem;
              text-align: center;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              padding-left: 0.1rem;
              padding-right: 0.1rem;
              font-size: 0.28rem;
              font-weight: bolder;
            }
          }
        }
      }
    }
    .video {
      .newscontainer {
        padding: 0.2rem 0.3rem 0 0.3rem;
        margin-right: auto;
        margin-left: auto;
        li {
          padding-bottom: 0.3rem;
          .row {
            display: -webkit-box;
            margin-right: -0.3rem;
            margin-left: -0.3rem;
            .col-xs-12 {
              width: 100%;
              position: relative;
              padding-right: 0.3rem;
              padding-left: 0.3rem;
              min-height: 1px;
              .pic {
                position: relative;
                img {
                  display: inline-block;
                  vertical-align: middle;
                  width: 100%;
                  height: 2.5rem;
                  border-radius: 12px;
                }
                span {
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  -webkit-transform: translate(-50%, -50%);
                  -moz-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
                  width: 1rem;
                  height: 1rem;
                  background: url(~@/assets/images/icon-video.png) no-repeat;
                  background-size: 100% auto;
                  cursor: pointer;
                }
              }
              h5 {
                font-weight: normal;
                margin: 0;
                padding: 0;
                a {
                  font-size: 0.28rem;
                  font-weight: bold;
                  color: #2a2a2a;
                  margin-top: 0.2rem;
                }
              }
              p {
                margin-top: 0.2rem;
                color: #787878;
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style scoped>
</style>
