import { request, METHOD } from "@/utils/request";
import { addurl } from "@/utils/util";
import { requestApiUrl } from "@/config/constConfig";
/**
 * 获取分类
 * @param pid
 */
 export async function getThemeList (pid) {
  return request(addurl('getList', requestApiUrl.requestTheme) , METHOD.GET,{
    pid:pid
  })
}
/**
 * 获取图书列表页
 * @param page
 * @param pagesize
 * @param online  0-下架，1-上架
 * @param keyword
 * @param type p-获取部分字段
 */
export async function getBookList(params) {
  return request(
    addurl("getList", requestApiUrl.requestBook) +
      "/" +
      params.page +
      "/" +
      params.pageSize,
    METHOD.GET,
    {
      query: params.keyword,
      online: 1,
      classid: params.classid,
      type: "p",
    }
  );
}
/**
 * 获取音频列表页
 * @param page
 * @param pagesize
 * @param online  0-下架，1-上架
 * @param keyword
 * @param type p-获取部分字段
 */
 export async function getAudioList(params) {
  return request(
    addurl("getList", requestApiUrl.requestAudio) +
      "/" +
      params.page +
      "/" +
      params.pageSize,
    METHOD.GET,
    {
      query: params.keyword,
      online: 1,
      classid: params.classid,
      type: "p",
    }
  );
}

/**
 * 获取视频列表页
 * @param page
 * @param pagesize
 * @param online  0-下架，1-上架
 * @param keyword
 * @param type p-获取部分字段
 */
export async function getVideoList(params) {
  return request(
    addurl("getList", requestApiUrl.requestVideo) +
      "/" +
      params.page +
      "/" +
      params.pageSize,
    METHOD.GET,
    {
      query: params.keyword,
      classid: params.classid,
      online: 1,
      type: "p",
    }
  );
}

/**
 * 获取pdf宽高
 */
 export async function getPdfWH(path) {
  return request(
    addurl("getPdfWH", requestApiUrl.requestGetPdfInfo),
    METHOD.GET,{
      path:path
    }
  );
}
