<template>
  <div>
    <div class="header">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 header-title">
            <h4>{{ bookDetails.name }}</h4>
            <!-- <a @click="goPrev" class="back"><i></i></a> -->
            <a
              @click="favoritesFun(bookDetails)"
              class="collect-pre"
              :class="{ nocollect: !favorites, collect: favorites }"
              ><i></i
            ></a>
          </div>
        </div>
      </div>
    </div>
    <div id="main">
      <div class="content-wrap">
        <!-- 图书信息 -->
        <div class="book-info">
          <div class="bookContent">
            <div class="row">
              <div class="col-xs-5">
                <a><img :src="bookDetails.pictruepath" /></a>
              </div>
              <div class="col-xs-7">
                <div class="keys-text">
                  <div class="txt">
                    <h4>{{ bookDetails.name }}</h4>
                    <p>
                      <span>{{ bookDetails.author }}</span
                      >著
                    </p>
                  </div>
                  <div class="edit-btn">
                    <a class="btn-read" @click="openPdf">在线阅读</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- //简介 作者 目录 -->
        <div
          class="detail-item"
          v-if="titleArr !== null && titleArr.length > 0"
        >
          <div class="item-nav">
            <div class="detailContainer">
              <a-row>
                <a-col
                  :span="
                    titleArr.length == 2 ? 12 : titleArr.length == 3 ? 8 : 24
                  "
                  v-for="(item, index) in titleArr"
                  :key="index"
                  @click="goContent(item)"
                >
                  <a :class="{ active: item.active }">{{ item.name }}</a>
                </a-col>
              </a-row>
            </div>
          </div>
          <div class="detail-con">
            <div class="detailContainer">
              <a-row>
                <a-col :span="24">
                  <div class="item-con item-con-1" v-if="type === '1'">
                    <p>
                      {{ bookDetails.digest }}
                    </p>
                  </div>
                  <div class="item-con item-con-2" v-if="type === '2'">
                    {{ bookDetails.authordesc }}
                  </div>
                  <div
                    class="item-con item-con-3"
                    v-if="type === '3' && tableOfContents.length > 0"
                  >
                    <div v-for="(item, index) in tableOfContents" :key="index">
                      {{ item.name }}
                    </div>
                    <div
                      class="more"
                      @click="goMore()"
                      v-if="
                        oldTableOfContents.length > 5 &&
                        tableOfContents.length < 6
                      "
                    >
                      <a>查看更多</a>
                    </div>
                  </div>
                </a-col>
              </a-row>
            </div>
          </div>
        </div>
        <!-- 更多图书信息 -->
        <div class="con-item more-info">
          <div class="detailContainer">
            <div class="titleName">/<span>更多图书信息</span>/</div>
            <a-row class="moreContent">
              <a-col :span="24">
                <p>
                  出版社：<span>{{ bookDetails.issuedep }}</span>
                </p>
                <p>
                  出版时间：<span>{{ bookDetails.issuedate }}</span>
                </p>
                <p>
                  ISBN：<span>{{ bookDetails.isbn }}</span>
                </p>
                <p>
                  字数：<span>{{ bookDetails.charcount }}</span>
                </p>
              </a-col>
            </a-row>
          </div>
        </div>
        <!-- 推荐 -->
        <div class="con-item rec">
          <div class="detailContainer">
            <div class="titleName">/<span>推荐图书</span>/</div>
            <div class="row">
              <div
                class="col-xs-4"
                v-for="(item, index) in recommendList"
                :key="index"
                @click="goDetail('MobileBookDetail', item.sys_fld_doi)"
              >
                <a>
                  <img :src="item.pictruepath" />
                  <p>{{ item.name }}</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="footerContainer">
          <div class="row">
            <div class="col-xs-12">
              <p>吉林省惠民数字阅读平台</p>
              <p>©2021</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import PdfStreamRead from "@/components/common/PdfBigStreamRead";
import * as homeApi from "@/services/home";
import { replacePath, replaceAll, isMobile } from "@/utils/util";
// import { swiper, swiperSlide } from "vue-awesome-swiper";
// import "swiper/dist/css/swiper.css";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "BookDetail",
  //   components: { swiper, swiperSlide, PdfStreamRead },
  data() {
    return {
      titleArr: [],
      type: "1",
      pdfUrl: "",
      bookDetails: "",
      tableOfContents: [], //当前目录
      oldTableOfContents: [], //全部目录
      recommendList: [], //推荐图书
      favorites: false,
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  watch: {
    $route(to) {
      this.doi = to.params.doi;
      this.getBookDetails();
      this.getRecommendList();
      this.type = "1";
    },
  },
  mounted() {},
  created() {
    this.Mobile();
    this.getBookDetails();
    //获取推荐图书
    this.getRecommendList();
  },
  methods: {
    ...mapMutations("account", ["saveNowRouter"]),
    Mobile() {
      if (!isMobile()) {
        this.$router.push({
          name: "bookDetail",
          params: { doi: this.$route.params.doi },
        });
      }
    },
    openPdf() {
       var vm = this;
      if (!this.user || this.user === "") {
        this.$confirm({
          title: "确认提示",
          content: `登录后即可阅读，是否确认登录？`,
          okType: "warning",
          onOk() {
            vm.saveNowRouter(vm.$route);
            vm.$router.push({ name: "Login" });
          },
          onCancel() {
            console.log("取消");
          },
        });
        return;
      }
      if (
        localStorage.getItem("expireAt") &&
        new Date().getTime() >=
          new Date(localStorage.getItem("expireAt")).getTime()
      ) {
        this.$confirm({
          title: "确认提示",
          content: `登录已过期，该操作需重新登录，是否重新登录？`,
          okType: "warning",
          onOk() {
            vm.saveNowRouter(vm.$route);
            vm.$router.push({ name: "Login" });
          },
          onCancel() {
            console.log("取消");
          },
        });
        return;
      }
      //this.pdfread = true
      // window.open(baseURL + '/registercode/exportExcel/' +_self.batchnumber, "_blank");path: '/article', query: {  id: 123 } }
      let link = this.$router.resolve({
        path: "/item/pdfRead",
        query: { pdfUrl: this.pdfUrl },
      });
      window.open(link.href, "_blank");
    },
    goDetail(name, doi) {
      this.$router.push({ name: name, params: { doi: doi } });
    },
    getRecommendList() {
      let vm = this;
      let obj = {
        // typeDoi: "666c6b27-8626-4027-a300-914a6eb16c43",
        typeDoi: "666c6b27-8626-4027-a300-914a6eb16c43",
        page: 1,
        size: 1000,
      };
      homeApi.getRecommendList(obj).then((res) => {
        if (res.success) {
          vm.recommendList = [];
          // this.recommendList = res.data.list;
          res.data.list.map((item, index) => {
            if (index < 6) {
              item.pictruepath = replacePath(item.sys_fld_coverpath);
              vm.recommendList.push(item);
            }
          });
        } else {
          this.recommendList = [];
        }
      });
    },
    goToView(path) {
      this.$router.push({ path: path });
    },
    goPrev() {
      this.$router.go(-1);
    },
    //查看更多目录
    goMore() {
      this.tableOfContents = this.oldTableOfContents;
    },
    //获取图书详情
    getBookDetails() {
      this.$emit("bindActive", "mobileSearch");
      let obj = {
        doi: this.$route.params.doi,
      };
      homeApi.getBookDetails(obj).then((res) => {
        if (res.success) {
          this.bookDetails = res.data.data;
          this.bookDetails.pictruepath = replacePath(
            this.bookDetails.sys_fld_coverpath
          );
          let flagdigest =
            this.bookDetails.digest !== null &&
            this.bookDetails.digest.length > 0;
          let flagauthor =
            this.bookDetails.authordesc !== null &&
            this.bookDetails.authordesc.length > 0;
          this.pdfUrl = replaceAll(this.bookDetails.sys_fld_filepath);
          this.getIsFav(this.bookDetails);
          this.getCatalogByid(
            this.bookDetails.sys_fld_doi,
            flagdigest,
            flagauthor
          );
          //增加浏览记录
          this.addLog(this.bookDetails);
        } else {
          this.bookDetails = {};
        }
      });
    },
    //获取当前目录
    getCatalogByid(id, flagdigest, flagauthor) {
      var vm = this;
      //获取当前目录
      var obj = {
        id: id,
      };
      this.tableOfContents = [];
      vm.titleArr = [];
      if (flagdigest) {
        vm.titleArr.push({ name: "简介", active: true, type: "1" });
      }
      if (flagauthor) {
        vm.titleArr.push({
          name: "作者",
          active: vm.titleArr !== null && vm.titleArr.length > 0 ? false : true,
          type: "2",
        });
      }
      this.oldTableOfContents = [];
      homeApi.getCatalogByid(obj).then((res) => {
        if (res.success) {
          vm.oldTableOfContents = res.data.list;
          vm.oldTableOfContents.map(function (item, index) {
            if (index < 5) {
              vm.tableOfContents.push(item);
            }
          });

          if (vm.tableOfContents.length > 0) {
            vm.titleArr.push({
              name: "目录",
              active:
                vm.titleArr !== null && vm.titleArr.length > 0 ? false : true,
              type: "3",
            });
          }
        } else {
          vm.tableOfContents = [];
          vm.oldTableOfContents = [];
        }
      });
    },
    goContent(item) {
      this.titleArr.map(function (data) {
        data.active = false;
      });
      item.active = true;
      this.type = item.type;
    },
    //收藏/取消收藏
    favoritesFun(item) {
      var vm = this;
      if (!this.user || this.user === "") {
        this.$confirm({
          title: "确认提示",
          content: `收藏需要先登录，是否确认登录？`,
          okType: "warning",
          onOk() {
            vm.saveNowRouter(vm.$route);
            vm.$router.push({ name: "Login" });
          },
          onCancel() {
            console.log("取消");
          },
        });
        return;
      }
      if (
        localStorage.getItem("expireAt") &&
        new Date().getTime() >=
          new Date(localStorage.getItem("expireAt")).getTime()
      ) {
        this.$confirm({
          title: "确认提示",
          content: `登录已过期，该操作需重新登录，是否重新登录？`,
          okType: "warning",
          onOk() {
            vm.saveNowRouter(vm.$route);
            vm.$router.push({ name: "Login" });
          },
          onCancel() {
            console.log("取消");
          },
        });
        return;
      }
      let obj = {
        booktype: "1",
        doi: this.$route.params.doi,
        name: item.name,
        operator: this.user,
      };
      homeApi.addFavoriteData(obj).then((res) => {
        if (res.success) {
          this.$message.success(res.message);
          this.favorites = !this.favorites;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //获取是否已经收藏
    getIsFav() {
      if (!this.user || this.user === "") {
        return;
      }
      let obj = {
        doi: this.$route.params.doi,
        restype: "1",
        username: this.user,
      };
      homeApi.getIsFav(obj).then((res) => {
        if (res.success) {
          this.favorites = true;
        } else {
          this.favorites = false;
        }
      });
    },
    //浏览记录
    addLog(item) {
      if (!this.user || this.user === "") {
        return;
      }
      if (
        localStorage.getItem("expireAt") &&
        new Date().getTime() >=
          new Date(localStorage.getItem("expireAt")).getTime()
      ) {
        return;
      }
      let obj = {
        resourcetype: "1",
        resourcedoi: this.$route.params.doi,
        resourcename: item.name,
        username: this.user,
      };
      homeApi.addLog(obj);
    },
  },
};
</script>
<style lang="less" scoped>
// @import "~@/assets/css/home.less";
.header {
  //margin-top: 1.2rem;
  z-index: 1;
  top: 0;
  width: 100%;
  padding: 12px 0 2px 0;
  position: fixed;
  background-color: #f0f2f5;
  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    .row {
      margin-right: -15px;
      margin-left: -15px;
      .header-title {
        position: relative;
        h4 {
          text-align: center;
          font-size: 0.28rem;
          color: #232323;
          font-weight: 600;
          padding: 0 1rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .back {
          position: absolute;
          left: 0.3rem;
          top: 0.08rem;
          font-size: 0.28rem;
          color: #fff;
          i {
            width: 0.2rem;
            height: 0.4rem;
            margin-top: -0.04rem;
            margin-right: 0.08rem;
            font-size: 0.28rem;
            color: #232323;
            background: url(~@/assets/images/icon-back.png) no-repeat;
            background-size: 100% auto;
            vertical-align: middle;
          }
        }
        .collect {
          position: absolute;
          right: 0.3rem;
          top: 0rem;
          i {
            width: 0.44rem;
            height: 0.48rem;
            background: url(~@/assets/images/icon-collect-pre.png) no-repeat;
            background-size: 100% auto;
          }
        }
        .nocollect {
          position: absolute;
          right: 0.3rem;
          top: 0rem;
          i {
            width: 0.44rem;
            height: 0.48rem;
            background: url(~@/assets/images/icon-collect.png) no-repeat;
            background-size: 100% auto;
          }
        }
      }
      .col-xs-12 {
        width: 100%;
      }
    }
  }
}
#main {
  margin-top: 0.85rem;
  .footer {
    padding: 15px 0;
    .footerContainer {
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
      .row {
        margin-right: -15px;
        margin-left: -15px;
        .col-xs-12 {
          width: 100%;
          padding-right: 15px;
          padding-left: 15px;
          p {
            text-align: center;
            font-size: 0.24rem;
            color: #333333;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .content-wrap {
    height: 100%;
    .book-info {
      width: 100%;
      height: auto;
      padding: 36px 0;
      background-size: 100% auto;
      background: url(~@/assets/images/bg-details.jpg) no-repeat center top;
      .bookContent {
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        .row {
          display: -webkit-box;
          margin-right: -15px;
          margin-left: -15px;
          .col-xs-5 {
            width: 41.66666667%;
            position: relative;
            min-height: 1px;
            padding-right: 15px;
            padding-left: 15px;
            a {
              color: #333333;
              text-decoration: none;
              display: inline-block;
              width: 100%;
              img {
                width: 100%;
                background-size: 100% auto;
                box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
                max-width: 100%;
                height: auto;
                border: 0;
                outline: none;
                vertical-align: middle;
                height: 3.5rem;
                // height: 3.5rem;
              }
            }
          }
          .col-xs-7 {
            display: -webkit-box;
            width: 58.33333333%;
            position: relative;
            min-height: 1px;
            padding-right: 15px;
            padding-left: 15px;
            .keys-text {
              color: #fff;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-box-flex: 1;
              -webkit-box-pack: justify;
              .txt {
                h4 {
                  width: 100%;
                  font-size: 0.28rem;
                  line-height: 0.48rem;
                  font-weight: bold;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  color: #fff;
                }
                p {
                  margin: 0.2rem 0 0.2rem 0;
                  font-size: 0.24rem;
                  color: #ff7e00;
                  span {
                    margin-right: 10px;
                  }
                }
              }
              .edit-btn {
                .btn-read {
                  padding: 0.2rem 0;
                  width: 60%;
                  border-radius: 24px;
                  font-size: 0.24rem;
                  color: #fff;
                  background: #e50000;
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
    .detail-item {
      .item-nav {
        padding: 12px 0;
        .detailContainer {
          padding-right: 15px;
          padding-left: 15px;
          margin-right: auto;
          margin-left: auto;
          a {
            width: 95%;
            padding: 15px 0;
            margin: -12px 0;
            font-size: 0.28rem;
            color: #676767;
            border-bottom: 2px solid #ebebeb;
          }
          .active {
            color: #040504;
            border-color: #e50101;
          }
        }
      }
      .detail-con {
        background: #fff;
        padding: 20px 0;
        .more {
          a {
            color: #0066cc;
          }
        }
        .detailContainer {
          padding-right: 15px;
          padding-left: 15px;
          margin-right: auto;
          margin-left: auto;
          .item-con {
            font-size: 0.24rem;
            line-height: 0.56rem;
            p {
              text-indent: 2em;
              font-size: 0.24rem;
              line-height: 0.56rem;
            }
          }
        }
      }
    }
    .rec {
      padding-bottom: 0 !important;
    }
    .con-item {
      margin-top: 0.1rem;
      background: #fff;
      padding: 20px 0;
      .detailContainer {
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        .titleName {
          text-align: center;
          font-size: 0.28rem;
          color: #b6b6b6;
          span {
            color: #333333;
            margin: 0 16px;
          }
        }
        .moreContent {
          p {
            font-size: 0.24rem;
            line-height: 0.56rem;
            margin-bottom: 0;
          }
        }
      }
      .row {
        margin-right: -15px;
        margin-left: -15px;
        padding-top: 20px;
        overflow: hidden;
        .col-xs-4 {
          margin-bottom: 20px;
          width: 33.33333333%;
          float: left;
          padding-left: 15px;
          padding-right: 15px;
          height: 4rem;
          a {
            color: #333333;
            text-decoration: none;
            width: 100%;
            img {
              width: 100%;
              height: auto;
              height: 2.7rem;
            }
            p {
              text-align: center;
              padding-top: 15px;
              font-size: 0.24rem;
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }
        }
      }
    }
  }
}
</style>
<style scoped>
.book-info >>> .ant-col-10 {
  padding-right: 15px;
}
.book-info >>> .ant-col-14 {
  padding-left: 15px;
}
.item-nav >>> .ant-row {
  margin-right: -15px;
  margin-left: -15px;
}
.item-nav >>> .ant-col {
  text-align: center;
  border-right: 1px solid #d4d4d3;
  padding-right: 15px;
  padding-left: 15px;
}
.detail-con >>> .ant-row {
  margin-right: -15px;
  margin-left: -15px;
}
.detail-con >>> .ant-col {
  padding-right: 15px;
  padding-left: 15px;
}
.con-item >>> .ant-row {
  margin-right: -15px;
  margin-left: -15px;
  padding-top: 20px;
}
.con-item >>> .ant-col {
  padding-right: 15px;
  padding-left: 15px;
}
</style>
